var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-container',{staticClass:"view-ecommerce-dashboard",attrs:{"title":"官網經營概況"}},[_c('section',{staticClass:"header-section"},_vm._l((_vm.headerWedgit),function(item,index){return _c('k-widget-chart',{key:("hwc_" + index),attrs:{"header":_vm.sum(item.key ? _vm.dealerGetters[item.key] : [6969]),"text":item.label,"type":item.chart,"data":item.key ? _vm.dealerGetters[item.key] : Array(12).fill(50),"label":item.label,"chartColor":_vm.COLOR_MAP[item.theme],"options":{
        elements: {
          point: { radius: 0 }
        }
      }}})}),1),_c('k-card',{staticClass:"traffic-chart",staticStyle:{"margin-bottom":"16px"},attrs:{"header":"官網營業額"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{class:{ 'traffic-info': true, 'hide': _vm.hideLine.includes('footer1') }},[_c('section',[_c('p',[_vm._v("月平均官網營業額")]),_c('k-checkbox',{attrs:{"value":"footer1","uncheckIcon":'eye-fill',"checkedIcon":'eye-slash-fill'},model:{value:(_vm.hideLine),callback:function ($$v) {_vm.hideLine=$$v},expression:"hideLine"}})],1),_c('b',[_vm._v("$"+_vm._s(parseInt(_vm.orderIncomeMonthly.reduce(function (acc, e) { return acc + e; }) / 12))+" / $100000")]),_c('k-progress',{attrs:{"value":parseInt(_vm.orderIncomeMonthly.reduce(function (acc, e) { return acc + e; }) / 12),"max":"100000","size":"thin","theme":"warning"}})],1),_c('div',{class:{ 'traffic-info': true, 'hide': _vm.hideLine.includes('footer3') }},[_c('section',[_c('p',[_vm._v("年度總收入")]),_c('k-checkbox',{attrs:{"value":"footer2","uncheckIcon":'eye-fill',"checkedIcon":'eye-slash-fill'},model:{value:(_vm.hideLine),callback:function ($$v) {_vm.hideLine=$$v},expression:"hideLine"}})],1),_c('b',[_vm._v("$"+_vm._s(_vm.cumulativeIncome[11])+" / $1200000")]),_c('k-progress',{attrs:{"value":_vm.cumulativeIncome[11],"max":"1200000","size":"thin","theme":"error"}})],1),_c('div',{class:{ 'traffic-info': true, 'hide': _vm.hideLine.includes('member') }},[_c('section',[_c('p',[_vm._v("平均客單價")])]),_c('b',[_vm._v("$"+_vm._s(_vm.ATV))]),_c('k-progress',{attrs:{"value":"100","size":"thin","theme":"success"}})],1)]},proxy:true}])},[_c('k-chart-bar',{attrs:{"height":"300","datasets":[
        {
          type: 'line',
          data: Array(12).fill(_vm.orderIncomeMonthly.reduce(function (acc, e) { return acc + e; }) / 12),
          backgroundColor: _vm.COLOR_MAP.light,
          borderColor: _vm.COLOR_MAP.light,
          borderDash: [10, 5],
          borderWidth: 1,
          label: 'month-avarage',
          hide: false,
          elements: {
            point: { radius: 0 }
          },
          yAxisID: 'y',
        },
        {
          data: _vm.cumulativeIncome,
          type: 'line',
          backgroundColor: _vm.COLOR_MAP.danger,
          borderColor: _vm.COLOR_MAP.danger,
          fill: false,
          hide: _vm.hideLine.includes('footer2'),
          borderWidth: 2,
          tension: 0,
          label: '年度營業總額累計',
          yAxisID: 'y1',
        },
        {
          data: _vm.orderIncomeMonthly,
          backgroundColor: _vm.COLOR_MAP.warning,
          borderColor: _vm.COLOR_MAP.warning,
          hide: _vm.hideLine.includes('footer1'),
          borderWidth: 2,
          tension: 0.4,
          label: '月營業額',
          fill: false,
          yAxisID: 'y',
        }
      ],"labels":"months","options":{
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.85,
        categoryPercentage: 0.7,
        scales: {
          x: { grid: { color: '#333' } },
          y: {
            type: 'linear', display: !_vm.hideLine.includes('footer1'), position: 'left',
            grid: { color: '#444' },
            ticks: { color: ((_vm.COLOR_MAP.warning) + "99") }
          },
          y1: {
            type: 'linear', display: !_vm.hideLine.includes('footer2'), position: 'right',
            grid: { display: _vm.hideLine.includes('footer1'), color: '#444' },
            ticks: { color: ((_vm.COLOR_MAP.danger) + "99") }
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom'
          }
        },
      }}})],1),_c('k-card',{staticClass:"user-order-list",attrs:{"header":"客戶消費排名","collapsible":false}},[_c('k-table',{attrs:{"items":_vm.monthlyUserList,"fields":_vm.fields,"hover":"","footerCaption":"","pagination":_vm.monthlyUserList.length > 10 ? _vm.pagination : false},scopedSlots:_vm._u([{key:"cell(user_name)",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(value || '未設定'))]),_c('k-tooltip',{attrs:{"title":"可升級為創始會員"}},[(
            _vm.filterOrderWithUser[item.user_id] && item.user_group !== 'ORIGINAL' &&
            _vm.filterOrderWithUser[item.user_id].reduce(function (acc, o) { return acc+parseInt(o.order_amount); }, 0) >= 3000
          )?_c('k-icon',{staticClass:"level-up",attrs:{"theme":"info","icon":"person-up"}}):_vm._e()],1)]}},{key:"cell(user_group)",fn:function(ref){
          var value = ref.value;
return [_c('k-tag',{attrs:{"theme":value === 'NORMAL' ? 'light' : 'info'}},[_vm._v(_vm._s(value === 'NORMAL' ? 'CF好友' : '創始會員'))])]}},{key:"cell(user_birthday)",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.moment().diff(_vm.timestampParse(parseInt(value)), 'years') : '未設定')+" ")]}},{key:"cell(user_consumption_amount)",fn:function(ref){
          var value = ref.value;
return [_vm._v(" $ "+_vm._s(value)+" / 3000 "),_c('k-progress',{attrs:{"max":3000,"value":value,"theme":value >= 3000 ? 'info' : 'success',"size":"thin"}})]}},{key:"cell(detail)",fn:function(ref){
          var item = ref.item;
return [(
            _vm.filterOrderWithUser[item.user_id] && item.user_group !== 'ORIGINAL' &&
            _vm.filterOrderWithUser[item.user_id].reduce(function (acc, o) { return acc+parseInt(o.order_amount); }, 0) >= 3000
          )?_c('k-button',{attrs:{"varient":"ghost","theme":"info"},on:{"click":function($event){return _vm.levelToOrginal(item)}}},[_vm._v("升級會員")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }