<template>
  <k-container
    class="view-ecommerce-dashboard"
    title="官網經營概況"
  >
    <section class="header-section">
      <k-widget-chart
        v-for="(item, index) in headerWedgit"
        :key="`hwc_${index}`"
        :header="sum(item.key ? dealerGetters[item.key] : [6969])"
        :text="item.label"
        :type="item.chart"
        :data="item.key ? dealerGetters[item.key] : Array(12).fill(50)"
        :label="item.label"
        :chartColor="COLOR_MAP[item.theme]"
        :options="{
          elements: {
            point: { radius: 0 }
          }
        }"
      />
    </section>

    <k-card
      class="traffic-chart"
      header="官網營業額"
      style="margin-bottom: 16px"
    >
      <k-chart-bar
        height="300"
        :datasets="[
          {
            type: 'line',
            data: Array(12).fill(orderIncomeMonthly.reduce((acc, e) => acc + e) / 12),
            backgroundColor: COLOR_MAP.light,
            borderColor: COLOR_MAP.light,
            borderDash: [10, 5],
            borderWidth: 1,
            label: 'month-avarage',
            hide: false,
            elements: {
              point: { radius: 0 }
            },
            yAxisID: 'y',
          },
          {
            data: cumulativeIncome,
            type: 'line',
            backgroundColor: COLOR_MAP.danger,
            borderColor: COLOR_MAP.danger,
            fill: false,
            hide: hideLine.includes('footer2'),
            borderWidth: 2,
            tension: 0,
            label: '年度營業總額累計',
            yAxisID: 'y1',
          },
          {
            data: orderIncomeMonthly,
            backgroundColor: COLOR_MAP.warning,
            borderColor: COLOR_MAP.warning,
            hide: hideLine.includes('footer1'),
            borderWidth: 2,
            tension: 0.4,
            label: '月營業額',
            fill: false,
            yAxisID: 'y',
          }
        ]"
        labels="months"
        :options="{
          responsive: true,
          maintainAspectRatio: false,
          barPercentage: 0.85,
          categoryPercentage: 0.7,
          scales: {
            x: { grid: { color: '#333' } },
            y: {
              type: 'linear', display: !hideLine.includes('footer1'), position: 'left',
              grid: { color: '#444' },
              ticks: { color: `${COLOR_MAP.warning}99` }
            },
            y1: {
              type: 'linear', display: !hideLine.includes('footer2'), position: 'right',
              grid: { display: hideLine.includes('footer1'), color: '#444' },
              ticks: { color: `${COLOR_MAP.danger}99` }
            }
          },
          plugins: {
            legend: {
              display: false,
              position: 'bottom'
            }
          },
        }"
      >
      </k-chart-bar>

      <template #footer>
        <div :class="{ 'traffic-info': true, 'hide': hideLine.includes('footer1') }">
          <section>
            <p>月平均官網營業額</p>
            <k-checkbox
              v-model="hideLine"
              value="footer1"
              :uncheckIcon="'eye-fill'"
              :checkedIcon="'eye-slash-fill'"
            />
          </section>
          <b>${{ parseInt(orderIncomeMonthly.reduce((acc, e) => acc + e) / 12) }} / $100000</b>
          <k-progress
            :value="parseInt(orderIncomeMonthly.reduce((acc, e) => acc + e) / 12)"
            max="100000"
            size="thin"
            theme="warning"
          />
        </div>
        <div :class="{ 'traffic-info': true, 'hide': hideLine.includes('footer3') }">
          <section>
            <p>年度總收入</p>
            <k-checkbox
              v-model="hideLine"
              value="footer2"
              :uncheckIcon="'eye-fill'"
              :checkedIcon="'eye-slash-fill'"
            />
          </section>
          <b>${{ cumulativeIncome[11] }} / $1200000</b>
          <k-progress
            :value="cumulativeIncome[11]"
            max="1200000"
            size="thin"
            theme="error"
          />
        </div>
        <div :class="{ 'traffic-info': true, 'hide': hideLine.includes('member') }">
          <section>
            <p>平均客單價</p>
            <!-- <k-checkbox
              v-model="hideLine"
              value="footer3"
              :uncheckIcon="'eye-fill'"
              :checkedIcon="'eye-slash-fill'"
            /> -->
          </section>
          <b>${{ ATV }}</b>
          <k-progress
            value="100"
            size="thin"
            theme="success"
          />
        </div>
      </template>
    </k-card>

    <k-card
      class="user-order-list"
      header="客戶消費排名"
      :collapsible="false"
    >
      <k-table :items="monthlyUserList" :fields="fields" hover footerCaption :pagination="monthlyUserList.length > 10 ? pagination : false">
        <template #cell(user_name)="{value, item}">
          <span>{{ value || '未設定' }}</span>
          <k-tooltip title="可升級為創始會員"><k-icon
            v-if="
              filterOrderWithUser[item.user_id] && item.user_group !== 'ORIGINAL' &&
              filterOrderWithUser[item.user_id].reduce((acc, o) => acc+parseInt(o.order_amount), 0) >= 3000
            "
            class="level-up"
            theme="info"
            icon="person-up"
          /></k-tooltip>
        </template>
        <template #cell(user_group)="{value}">
          <k-tag :theme="value === 'NORMAL' ? 'light' : 'info'">{{ value === 'NORMAL' ? 'CF好友' : '創始會員' }}</k-tag>
        </template>
        <template #cell(user_birthday)="{value}">
          {{ value ? moment().diff(timestampParse(parseInt(value)), 'years') : '未設定' }}
        </template>
        <template #cell(user_consumption_amount)="{ value }">
          $ {{ value }} / 3000
          <k-progress :max="3000" :value="value" :theme="value >= 3000 ? 'info' : 'success'" size="thin" />
        </template>
        <template #cell(detail)="{ item }">
          <k-button
            v-if="
              filterOrderWithUser[item.user_id] && item.user_group !== 'ORIGINAL' &&
              filterOrderWithUser[item.user_id].reduce((acc, o) => acc+parseInt(o.order_amount), 0) >= 3000
            "
            @click="levelToOrginal(item)"
            varient="ghost" theme="info">升級會員</k-button>
        </template>
      </k-table>
    </k-card>
  </k-container>
</template>

<script>
import { KCheckbox } from '@/components/Form'
import { KWidgetChart, KWidgetBrand } from '@/components/Widget'
import { KChartLine, KChartDoughnut, KChartBubble, KChartBar } from '@/components/Chart'
import { KProgress, KProgressBar } from '@/components/Progress'
import KTable from '@/components/Table'
import KCard from '@/components/Card'
import KTag from '@/components/Tag'
import KTooltip from '@/components/Tooltip'
import KButton from '@/components/Button'
import { COLOR_MAP, getRandom, timestampParse } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Dealer-Dashboard',
  mounted() {
    if (!this.$store.state.orders.ordersList.length) this.$store.dispatch('orders/query')
    if (!this.$store.state.user.userList.length) this.$store.dispatch('user/query')
  },
  data() {
    return {
      COLOR_MAP,
      headerWedgit,
      hideLine: [],
      fields: [
        { key: 'index', fieldIndex: 'index', label: ''},
        { key: 'user_name', fieldIndex: 'user_name', label: '用戶名稱', searchable: true,
          sorter: (a, b) => ('' + a).localeCompare(b)
        },
        { key: 'user_group', fieldIndex: 'user_group', label: '會員等級', filters: [
          { value: 'NORMAL', text: 'CF好友' },
          { value: 'ORIGINAL', text: '創始會員' }
        ]},
        { key: 'user_birthday', fieldIndex: 'user_birthday', label: '年齡', sorter: (a, b) => a - b }, 
        { key: 'user_consumption_amount', fieldIndex: 'user_consumption_amount', label: '累計消費金額' },
        { key: 'detail', fieldIndex: 'detail', label: '' }, 
      ],
    }
  },
  methods: {
    ...mapActions('user', ['levelUpMember']),
    ...mapActions('edm', ['sendEmail']),
    timestampParse,
    moment,
    getRandom,
    sum(arr) {
      return arr.reduce((arr, e) => arr + e)
    },
    levelToOrginal(user) {
      this.$confirm.info({
        title: '確定要將此會員等級升級為『創始會員』？',
        text: `會員：${user.user_name} | 累計消費金額：$${user.user_consumption_amount}`,
        icon: 'person-up',
        callback: async (bool) => {
          if (bool) {
            await this.levelUpMember(user.user_id)
            await this.sendEmail({
              systemId: 'ORIGINAL_MAMBER_QUALIFY',
              user_id: user.user_id,
              recipients : [{ name: user.user_name, address: user.user_email }]
            })
            this.$message.success('已將會員升級為創始會員')
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters('dealer', ['dealerGrowth', 'dealerOrderIncomeMonthly', 'cumulativePurchasingIncome', 'productOrderMonthly']),
    ...mapGetters('orders', ['orderIncomeMonthly', 'cumulativeIncome', 'ATV', 'monthlyOrderCount', 'filterOrderWithUser']),
    ...mapGetters('user', ['monthlyNewUser', 'userDict']),
    dealerGetters() {
      return {
        monthlyOrderCount: this.monthlyOrderCount,
        monthlyNewUser: this.monthlyNewUser,
        quantity5Monthly: this.productOrderMonthly.fibra_gel_5,
      }
    },
    bubbleFackData() {
      let data = []
      for (let index = 0; index < 50; index++) {
        let type = getRandom(0, 1)
        data.push({
          data: [{ x: this.getRandom(20, 50), y: this.getRandom(20, 50), v: 400 }],
          label: 'test1',
          borderWidth: 3,
          backgroundColor: type ? COLOR_MAP.info + '80' : COLOR_MAP.warning + '80',
          borderColor: type ? COLOR_MAP.info : COLOR_MAP.warning
        })
      }
      return data
    },
    sumOfPurchasing() {
      if (this.cumulativePurchasingIncome.length) {
        return this.cumulativePurchasingIncome.reduce((acc, e) => acc + e)
      }
      return 0
    },
    monthlyUserList() {
      if (Object.keys(this.filterOrderWithUser).length && this.userDict) {
        return Object.keys(this.filterOrderWithUser).map(user_id => {
          return {
            ...this.userDict[user_id],
            user_consumption_amount: this.filterOrderWithUser[user_id].reduce((acc, o) => acc + parseInt(o.order_amount), 0)
          }
        }).sort((a,b) => b.user_consumption_amount - a.user_consumption_amount).map((u, index) => ({ index: index+1, ...u }))
      }
      return []
    }
  },
  components: {
    KWidgetChart, KWidgetBrand, KCard, KCheckbox,
    KProgress, KProgressBar, KChartLine, KChartDoughnut, KChartBubble, KChartBar, KTable, KTag, KTooltip, KButton
  }
}

const headerWedgit = [
  { theme: 'info', label: '各月份訂單數', chart: 'bar', key: 'monthlyOrderCount' },
  { theme: 'primary', label: '各月份新會員數', chart: 'bar', key: 'monthlyNewUser' },
  { theme: 'error', label: '5*5ml 每月訂購數量', chart: 'bar', key: 'quantity5Monthly' },
  { theme: 'secondary', label: '還沒想到要放啥', chart: 'line' },
]
</script>
