import { genBasicModel } from './utils/basicAction'
import { create, update } from './utils/basicService'
import { createReverseLogisticsOrder } from '@/utils/ecpay/ecpay.js'
import { copySheet, connectSheet, updateSheet } from '@/utils/googleSheet'
import { TapPayRefund } from "@/utils/tappay.js"
import { fetchApi } from '@/utils'
import ecpayLogisticsState from "@/utils/ecpay/ecpayLogisticsState.json"
import database from '@/utils/database'
import moment from 'moment'
import { timestampParse } from '@/utils'

const MODEL_NAME = 'orders'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  void 8,
  create(MODEL_NAME, true),
  update(MODEL_NAME))

// async function query() {
//   const res = await database.table(MODEL_NAME)
//     .join('return_order', 'orders_id')
//     .join('order_log', 'orders_id')
//     .join('user', 'user_id')
//     .select([
//       "orders.*",
//       "user.user_id, GROUP_CONCAT(order_log.order_log_operation SEPARATOR ',') AS order_log_operations", 
//       "GROUP_CONCAT(order_log.order_log_create_at SEPARATOR ', ') AS order_log_times"
//     ])
//     .groupBy('orders.orders_id')
//     .orderBy('orders.order_create_at', 'desc')
//     .get()
//     console.log(res);
//   let orderList = res.data.map(o => {
//     const order_products = JSON.parse(o.order_products)
//     const order_discount = -1*(order_products.reduce((acc,p)=>acc+p.product_price*p.product_quantity, 0) - o.order_amount - o.order_voucher_used)
//     return {
//       ...o,
//       order_logistics_state: JSON.parse(o.order_logistics_state) || [],
//       order_coupon_used: JSON.parse(o.order_coupon_used) || [],
//       order_logistics_info: JSON.parse(o.order_logistics_info),
//       order_logistics_cvs_info: JSON.parse(o.order_logistics_cvs_info),
//       order_return_info: JSON.parse(o.order_return_info),
//       order_shipping_fee: (parseInt(o.order_amount) + order_discount) >= 1000 ? 0 : 120,
//       order_log_operations: o.order_log_operations ? o.order_log_operations.split(',') : [],
//       order_products,
//       order_discount
//     }
//   })
//   const result = await fetchApi("/api/ecpay/getLogisticsState/", { orders: orderList.filter(o => {
//     if (o.order_pay_type === "COD") return false 
//     if (!o.order_logistics_state.length) return true
//     let newestState = o.order_logistics_state[o.order_logistics_state.length-1].code
//     switch (o.order_logistics_subtype) {
//       case "TCAT": return !["3003", "20000"].includes(newestState)
//       case "UNIMARTC2C": return !["2067", "20000"].includes(newestState)
//       case "FAMIC2C": return !["3022", "20000"].includes(newestState)
//     }
//   }).map(o => ({
//     MerchantTradeNo: o.order_logistics_id,
//     TimeStamp: moment().unix()
//   }))})
//   const logisticsStateStoreRule = Object.keys(ecpayLogisticsState).reduce((acc, type) => ({
//     ...acc, [type]: Object.keys(ecpayLogisticsState[type])
//   }), logisticsStateStoreRule)
//   res.data = result.response.reduce((acc, state) => {
//     let index = acc.map(o => o.order_logistics_id).indexOf(state.MerchantTradeNo)
//     let logisticsSubtype = state.LogisticsType.split("_")[1]
//     acc.splice(index, 1, {
//         ...acc[index],
//       order_logistics_state: [
//         ...acc[index].order_logistics_state,
//         { code: state.LogisticsStatus }
//       ].sort((a, b) => logisticsStateStoreRule[logisticsSubtype].indexOf(a.code) - logisticsStateStoreRule[logisticsSubtype].indexOf(b.code))
//     })
//     return acc
//   }, orderList)
//   return res
// }

function setAttributes(el, attrs) {
  for(var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

export default {
  namespaced: true,
  state: {
    ordersList: [],
    returnList: []
  },
  mutations: {
    ...basicMutation,
    renewOrderLogisticsState(state, { data }) {
      data.forEach(o => {
        let index = state.ordersList.map(e => e.key_id).indexOf(o.key_id)
        state.ordersList.splice(index, 1, {
          ...state.ordersList[index],
          order_logistics_state: o.order_logistics_state
        })
      })
    },
    batchChangeState(state, { check_orders }) {
      let orders_id_list = state.ordersList.map(e => e.key_id)
      check_orders.forEach(order => {
        let index = orders_id_list.indexOf(order.orders_id)
        state.ordersList.splice(index, 1, { ...state.ordersList[index], order_logistics_state: [
          ...order.order_logistics_state,
          { code: "20000", update_time: moment().format('YYYY/MM/DD  HH:mm:ss') }
        ] })
      })
    },
    batchLogOperation(state, { orders, operation }) {
      let orders_id_list = state.ordersList.map(e => e.key_id)
      orders.forEach(o => {
        let index = orders_id_list.indexOf(o.orders_id)
        state.ordersList.splice(index, 1, {
          ...state.ordersList[index],
          order_log_operations: [...state.ordersList[index].order_log_operations, operation],
          order_logistics_state: operation === 'SHIPPED'
            ? [...state.ordersList[index].order_logistics_state, { code:"900", update_time: moment().format("YYYY\/MM\/DD HH:mm:ss") }]
            : state.ordersList[index].order_logistics_state
      })
      })
    },
    issueVoucher(state, payload) {
      const order_index = state.ordersList.map(o => o.orders_id).indexOf(payload)
      state.ordersList.splice(order_index, 1, {
        ...state.ordersList[order_index],
        order_log_operations: [...state.ordersList[order_index].order_log_operations, 'ISSUE_VOUCHER']
      })
    },
    archive(state, payload) {
      const order_index = state.ordersList.map(o => o.orders_id).indexOf(payload)
      state.ordersList.splice(order_index, 1, {
        ...state.ordersList[order_index],
        order_type: 'ARCHIVE'
      })
    },
    archiveAndReturn(state, payload) {
      const order_index = state.ordersList.map(o => o.orders_id).indexOf(payload.orders_id)
      state.ordersList.splice(order_index, 1, {
        ...state.ordersList[order_index],
        order_type: 'ARCHIVE',
        order_payment_status: payload.order_payment_status || state.ordersList[order_index].order_payment_status,
        order_logistics_state: JSON.parse(payload.order_logistics_state)
      })
    }
  },
  actions: {
    ...basicAction,
    async query({ commit }) {
      const res = await database.table(MODEL_NAME)
        .join('return_order', 'orders_id')
        .join('order_log', 'orders_id')
        .join('user', 'user_id')
        .select([
          "orders.*",
          "user.user_id, GROUP_CONCAT(order_log.order_log_operation SEPARATOR ',') AS order_log_operations", 
          "GROUP_CONCAT(order_log.order_log_create_at SEPARATOR ', ') AS order_log_times"
        ])
        .groupBy('orders.orders_id')
        .orderBy('orders.order_create_at', 'desc')
        .get()
        console.log(res);
      let orderList = res.data.map(o => {
        const order_products = JSON.parse(o.order_products)
        const order_total = order_products.reduce((acc,p)=>acc+p.product_price*p.product_quantity, 0)
        const order_discount = -1*(order_total - o.order_amount - o.order_voucher_used + (order_total < 1000 ? 120 : 0))
        return {
          ...o,
          order_logistics_state: JSON.parse(o.order_logistics_state) || [],
          order_coupon_used: JSON.parse(o.order_coupon_used) || [],
          order_logistics_info: JSON.parse(o.order_logistics_info),
          order_logistics_cvs_info: JSON.parse(o.order_logistics_cvs_info),
          order_return_info: JSON.parse(o.order_return_info),
          order_shipping_fee: (parseInt(o.order_amount) + order_discount) >= 1000 ? 0 : 120,
          order_log_operations: o.order_log_operations ? o.order_log_operations.split(',') : [],
          order_products,
          order_discount
        }
      })
      commit('save', { name: 'ordersList', data: orderList })
      const renewLogisticsList = orderList.filter(o => {
        if (o.order_pay_type === "COD") return false 
        if (!o.order_logistics_state.length) return true
        let newestState = o.order_logistics_state[o.order_logistics_state.length-1].code
        switch (o.order_logistics_subtype) {
          case "TCAT": return !["3003", "20000"].includes(newestState)
          case "UNIMARTC2C": return !["2067", "20000"].includes(newestState)
          case "FAMIC2C": return !["3022", "20000"].includes(newestState)
        }
      })
      const result = await fetchApi("/api/ecpay/getLogisticsState/", { orders: renewLogisticsList.map(o => ({
        MerchantTradeNo: o.order_logistics_id,
        TimeStamp: moment().unix()
      }))})
      const logisticsStateStoreRule = Object.keys(ecpayLogisticsState).reduce((acc, type) => ({
        ...acc, [type]: Object.keys(ecpayLogisticsState[type])
      }), logisticsStateStoreRule)
      const renewData = result.response.reduce((acc, state) => {
        let index = acc.map(o => o.order_logistics_id).indexOf(state.MerchantTradeNo)
        let logisticsSubtype = state.LogisticsType.split("_")[1]
        if (acc[index].order_logistics_state.map(s => s.code).includes(state.LogisticsStatus)) return acc
        acc.splice(index, 1, {
            ...acc[index],
          order_logistics_state: [
            ...acc[index].order_logistics_state,
            { code: state.LogisticsStatus }
          ].sort((a, b) => logisticsStateStoreRule[logisticsSubtype].indexOf(a.code) - logisticsStateStoreRule[logisticsSubtype].indexOf(b.code))
        })
        return acc
      }, renewLogisticsList)
      console.log(res);
      commit('renewOrderLogisticsState', { name: 'ordersList', data: renewData })
    },
    async queryReturns({ commit }) {
      const res = await database.table('return_order')
        .join('orders', 'orders_id')
        .join('user', 'user_id', 'orders', 'user_id')
        .orderBy('return_order.return_order_create_at', 'desc')
        .get()
      res.data = res.data.map(o => ({
        ...o,
        order_logistics_state: JSON.parse(o.order_logistics_state) || [],
        order_products: JSON.parse(o.order_products),
        order_coupon_used: JSON.parse(o.order_coupon_used),
        order_logistics_info: JSON.parse(o.order_logistics_info),
        order_logistics_cvs_info: JSON.parse(o.order_logistics_cvs_info)
      }))
      commit('save', { name: 'returnList', data: res.data })
    },
    async batchCheckState({ commit }, payload) {
      const { check_orders } = payload
      const promises = check_orders.reduce((acc, order) => [...acc,  new Promise(async (res, rej) => {
        const states = order.order_logistics_state
        try {
          await database.table(MODEL_NAME).where('orders_id', '=', order.orders_id).update({
            order_logistics_state: JSON.stringify([...states, { code: "20000", update_time: moment().format('YYYY/MM/DD HH:mm:ss') }])
          })
          res()
        } catch (error) {
          console.log(error);
          rej(error)
        }
      })], [])
      await Promise.all(promises)
      commit('batchChangeState', { check_orders })
    },
    async confirmReturnApply({ commit, dispatch }, payload) {
      const res = await createReverseLogisticsOrder(payload)
      const returnOrderRes = await database.table('return_order')
        .where('orders_id', '=', payload.orders_id)
        .update({ return_order_state: 'RETURN_CONFIRM' })
      const databaseRes = await dispatch('update', { id: payload.orders_id, order_type: 'RETURN' })
      commit('update', { name: 'ordersList', data: { id: payload.orders_id, order_type: 'RETURN' } })
      commit('update', { name: 'returnList', data: { id: payload.return_order_id, return_order_state: 'RETURN_CONFIRM' } })
      console.log(res);
      console.log(returnOrderRes);
      console.log(databaseRes);
      return res
    },
    async archive({ commit }, payload) {
      // await database.table('orders').where('orders_id', '=', payload).update({ order_type: 'ARCHIVE' })
      commit('archive', payload)
    },
    async archiveUppickedOrder({ commit }, payload) {
      const { orders_id, order_pay_type } = payload
      await database.table('orders').where('orders_id', '=', orders_id).update({
        order_type: 'ARCHIVE',
        order_payment_status: order_pay_type === 'CREDIT' ? 'RETURN' : undefined,
        order_logistics_state: JSON.stringify([
          ...payload.order_logistics_state,
          { code: "40000", update_time: moment().format('YYYY/MM/DD HH:mm:ss') }
        ])
      })
      commit('archiveAndReturn', {
        orders_id,
        order_payment_status: order_pay_type === 'CREDIT' ? 'RETURN' : undefined,
        order_logistics_state: JSON.stringify([
          ...payload.order_logistics_state,
          { code: "40000", update_time: moment().format('YYYY/MM/DD HH:mm:ss') }
        ])
      })
    },
    async refundOrder({ commit, dispatch }, order) {
      // const res = await TapPayRefund({ rec_trade_id: order.order_rec_trade_id })
      const returnOrderRes = await database.table('return_order')
        .where('orders_id', '=', order.orders_id)
        .update({ return_order_state: 'REFUNDED' })
      const databaseRes = await dispatch('update', { id: order.orders_id, order_type: 'REFUNDED' })
      commit('update', { name: 'ordersList', data: { id: order.orders_id, order_type: 'REFUNDED' } })
      commit('update', { name: 'returnList', data: { id: order.return_order_id, return_order_state: 'REFUNDED' } })
      // return res
    },
    async genPortSheet({ commit, rootGetters }, payload) {
      const { orders_id } = payload
      const title = `${orders_id} | ${payload.order_logistics_info.ReceiverName} 出貨單`
      console.log(genOrderPortCells(payload, rootGetters['product/productDict'], rootGetters['product/productUidDict']));
      const doc = await connectSheet(process.env.VUE_APP_ECOMMERCE_PORT_DOC_ID)
      const sheet = await copySheet(doc)
      await updateSheet(sheet, { title }, genOrderPortCells(payload, rootGetters['product/productDict'], rootGetters['product/productUidDict']), "A7:F29")
      const sheetURL = `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_ECOMMERCE_PORT_DOC_ID}/edit#gid=${sheet.sheetId}`
      await database.table('orders').where('orders_id', '=', orders_id).update({ order_port_doc_url: sheetURL })
      commit('update', { name: 'ordersList', data: { id: orders_id, order_port_doc_url: sheetURL } })
      return { docURL: sheetURL }
    },
    async logOperation({ commit, rootState }, payload) {
      const { orders, operation } = payload
      const promises = orders.map(o => new Promise(async(res, rej) => {
        try {
          if (operation === 'RECEIPT') {
            await database.table('orders').where('orders_id', '=', o.orders_id).update({ order_receipt_id: o.order_receipt_id })
          }
          if (operation === 'SHIPPED') {
            await database.table('orders').where('orders_id', '=', o.orders_id).update({
              order_logistics_state: JSON.stringify([
                ...o.order_logistics_state,
                { code:"900", update_time: moment().format("YYYY\/MM\/DD HH:mm:ss") }
              ])
            })
          }
          await database.table('order_log').set({
            orders_id: o.orders_id,
            admin_id: rootState.login.currentUser.admin_id,
            order_log_operation: operation,
            order_log_create_at: database.FieldValue.serverTimestamp()
          })
          res()
        } catch (error) {
          rej(error)
        }
      }))

      try {
        await Promise.all(promises)
        commit('batchLogOperation', { orders, operation })
        return { state: 200 }
      } catch (error) {
        return { state: 500, msg: error }
      }
    },
    async updateCODOrderBookingNote({ commit }, payload) {
      console.log(payload);
      const { orders_id, order_logistics_info } = payload
      const res = await database.table('orders').where('orders_id', '=', orders_id).update({ order_logistics_info: JSON.stringify(order_logistics_info) })
      console.log(res);
      commit('update', { name: 'ordersList', data: { id: orders_id, order_logistics_info } })
      return res
    },
    async issueVoucher({ commit, rootState }, payload) {
      const { orders_id, user_id, order_voucher_receivable, order_binding_voucher_receivable, order_binding_user } = payload
      if (order_voucher_receivable) {
        await database.table('voucher').set({
          voucher_type: 'SHOP_BACK',
          user_id,
          orders_id,
          voucher_value: order_voucher_receivable,
          voucher_period: 31536000,
          voucher_create_at: database.FieldValue.serverTimestamp()
        })
      }
      if (order_binding_voucher_receivable) {
        await database.table('voucher').set({
          voucher_type: 'FRIEND_BACK',
          user_id: order_binding_user.user_id,
          orders_id,
          voucher_value: order_binding_voucher_receivable,
          voucher_period: 31536000,
          voucher_create_at: database.FieldValue.serverTimestamp()
        })
      }
      await database.table('order_log').set({
        admin_id: rootState.login.currentUser.admin_id,
        orders_id, order_log_operation: 'ISSUE_VOUCHER',
        order_log_create_at: database.FieldValue.serverTimestamp()
      })
      commit('issueVoucher', orders_id)
      return { status: 200 }
    }
  },
  getters: {
    returnListCount({ returnList }) {
      if (returnList.length) {
        return returnList.filter(e => e.return_order_state === 'RETURN_APPLY').length
      }
      return 0
    },
    orderIncomeMonthly({ ordersList }) {
      if (ordersList.length) {
        const thisYear = moment([2023, 0, 1]).unix()
        return ordersList.filter(o => o.order_create_at > thisYear &&  (o.order_pay_type === 'COD' || o.order_payment_status === 'SUCCESS') && o.order_type === 'BUY').reduce((acc, o) => {
          const month = parseInt(timestampParse(o.order_create_at).format('M')) - 1
          acc[month] += parseInt(o.order_amount)
          return acc
        }, Array(12).fill(0))
      }
      return  Array(12).fill(0)
    },
    cumulativeIncome: (_, { orderIncomeMonthly }) => {
      if (orderIncomeMonthly.length) {
        return orderIncomeMonthly.reduce((acc, ma, index) => {
          if (index > 0) acc[index] = acc[index - 1] + ma
          return acc
        }, JSON.parse(JSON.stringify(orderIncomeMonthly)))
      }
      return Array(12).fill(0)
    },
    ATV: ({ ordersList }) => {
      if (ordersList.length) {
        const thisYear = moment([2023, 0, 1]).unix()
        let purchasingOrders = ordersList.filter(o => o.order_create_at > thisYear &&  (o.order_pay_type === 'COD' || o.order_payment_status === 'SUCCESS')  && o.order_type === 'BUY')
        if (purchasingOrders.length) return Math.round(purchasingOrders.reduce((acc, o) => acc + parseInt(o.order_amount), 0) / purchasingOrders.length)
      }
      return 0
    },
    monthlyOrderCount: ({ ordersList }) => {
      if (ordersList.length) {
        const thisYear = moment([2023, 0, 1]).unix()
        let purchasingOrders = ordersList.filter(o => o.order_create_at > thisYear &&  (o.order_pay_type === 'COD' || o.order_payment_status === 'SUCCESS')  && o.order_type === 'BUY')
        return purchasingOrders.reduce((acc, o) => {
          const month = parseInt(timestampParse(o.order_create_at).format('M')) - 1
          acc[month] += 1
          return acc
        }, Array(12).fill(0))
      }
      return Array(12).fill(0)
    },
    filterOrderWithUser: ({ ordersList }) => {
      if (ordersList.length) {
        let purchasingOrders = ordersList.filter(o => (o.order_pay_type === 'COD' || o.order_payment_status === 'SUCCESS'))
          .sort((a , b) => a.order_create_at - b.order_create_at)
        return purchasingOrders.reduce((acc, o) => {
          return { ...acc, [o.user_id]: [...(acc[o.user_id] || []), { ...o }]}
        }, {})
      }
      return {}
    },
    archiveOrder: ({ ordersList }) => {
      if (ordersList.length) {
        return ordersList.filter(o => o.order_type === 'ARCHIVE')
      }
      return []
    }
  }
}

const addressRegex = /\W{2}[縣市]\W{1,}[鄉鎮市區]/g
const genOrderPortCells = (order, productDict, productUidDict) => {
  let raw = 12
  const productCells = order.order_products.reduce((acc, product) => {
    raw += 1
    const productInfo = productDict[product.product_id]
    return { ...acc,
      [`A${raw}`]: productInfo.product_uid,
      [`B${raw}`]: productInfo.product_name_zh,
      [`D${raw}`]: product.product_price,
      [`E${raw}`]: product.product_quantity,
      [`F${raw}`]: product.product_price * product.product_quantity,
    }
  }, {})
  const giveawayCells = order.order_coupon_used.filter(c => ['GIVEAWAY', 'BONUS'].includes(c.coupon_type)).reduce((acc, coupon) => {
    return { 
      ...acc,
      ...coupon.coupon_items.reduce((innerAcc, item) => {
        const productInfo = productUidDict[item.product_uid]
        raw+=1
        return {
          ...innerAcc,
          [`A${raw}`]: productInfo.product_uid,
          [`B${raw}`]: productInfo.product_name_zh,
          [`D${raw}`]: '贈',
          [`E${raw}`]: 1,
          [`F${raw}`]: '贈',
        }
      }, {})
    }
  }, {})
  return {
    'B7': order.key_id, //訂單編號
    'E7': order.order_logistics_info.ReceiverName , //收件人
    'B8': order.order_logistics_info[order.order_logistics_type === 'CVS' ? 'CVSPaymentNo' : 'BookingNote'], //物流編號
    'E8': order.order_logistics_info.ReceiverCellPhone, //收件人電話
    'B9': moment().format('YYYY/MM/DD'), //出貨日期
    'E9': order.order_logistics_type === 'CVS'
      ? `${order.order_logistics_subtype === 'FAMIC2C' ? '全家' : '7-11'} - ${order.order_logistics_cvs_info.CVSStoreName}`
      :order.order_logistics_info.ReceiverAddress && order.order_logistics_info.ReceiverAddress.match(addressRegex)[0], //收件縣市
    'E10': order.order_logistics_type === 'CVS'
      ? order.order_logistics_cvs_info.CVSAddress
      : order.order_logistics_info.ReceiverAddress.replace(addressRegex, ''), //收件地址,
    ...productCells, //商品資訊
    ...giveawayCells, //贈品資訊
    'F25': order.order_discount //折扣
}
}
