<template>
  <k-container class="view-group-focus">
    <k-widget-icon
      theme="success"
      icon="bag-plus-fill"
      :header="focusProject ? focusProject.group_project_name : ''"
      :text="focusProject ? timestampParse(focusProject.group_project_start).format('YYYY/MM/DD') + ' - ' + timestampParse(focusProject.group_project_end).format('YYYY/MM/DD') : ''"
    >
    </k-widget-icon>

    <k-card class="group-products" header="團購商品" collapsible>
      <k-table :items="projectProducts" :fields="ProductFields" hover>
        <template #cell(file_path)="{item}">
          <img
            v-if="item.product_type === 'PRODUCT'"
            @error="e => ImgError(e)"
            :src="
              `https://chris-farrell.com.tw/img/product/${item.product_uid}/${layoutDict['PRODUCT_AVATAR_NAME'].layout_content || 'avatar'}.webp`
            "
          >
          <div class="img-wrapper" v-else>
            <img :src="`https://chris-farrell.com.tw/img/product/${item.product_images[0]}`">
          </div>
        </template>
        <template #cell(product_name_zh)="{value, item}">{{ item.product_name_en }}<br/>{{ value }}</template>
        <template #cell(group_product_price)="{value, item}">
          <p class="member-price" v-if="value">${{ item.product_price.MEMBER }}</p>
          <p>{{ value ? `$${value}` : '贈品' }}</p>
        </template>
        <template #cell(product_type)="{value}">{{ value==='PRODUCT' ? '單品' : '組合' }}</template>
        <template #cell(group_product_sales)="{item}">{{ groupProductSales[item.product_id] || '-' }}</template>
      </k-table>
    </k-card>

    <k-card header="團購訂單">
      <k-table :items="focusProjectOrders" :fields="field" :rowSelection="true" @check="handleCheck" hover>
        <template #cell(group_order_amount)="{value}">
          $ {{ value}}
        </template>

        <template #cell(group_order_logistics_info)="{value}">
          <k-tag :theme="value.LogisticsSubType === 'UNIMARTC2C' ? 'success' : 'info'">
            {{ value.LogisticsSubType === 'UNIMARTC2C' ? '7-11 統一超商' : 'FamilyMart全家' }}
          </k-tag>
        </template>
        
        <template #cell(group_order_state)="{value}">
          <k-tag :theme="value ? 'primary' : 'secondary'">{{ value ? '已出貨' : '未出貨' }}</k-tag>
        </template>
        
        <template #cell(group_order_create_at)="{value}">
          {{ timestampParse(value).format('YYYY-MM-DD HH:mm') }}
        </template>

        <template #cell(action)="{item}">
          <div class="button-group">
            <k-button @click="printDoc(item)" varient="ghost" theme="info">列印</k-button>
            <k-button @click="handlePort(item)" varient="ghost" theme="warning">出貨</k-button>
          </div>
        </template>
      </k-table>
    </k-card>
  </k-container>
</template>

<script>
import { KWidgetIcon, KWidgetImage } from '@/components/Widget'
import KCard from '@/components/Card'
import KTable from '@/components/Table'
import KTag from '@/components/Tag'
import KButton from '@/components/Button'
import { mapActions, mapGetters, mapState } from 'vuex'
import { timestampParse } from '@/utils'
import moment from 'moment'
import Template from '@/utils/template'
import { GROUP_PDF_TEMPLATE } from '@/utils/pdfTemplate'

export default {
  async mounted() {
    const { address } = this.$route.params
    if (!this.$store.state.group.group_projectList.length) await this.$store.dispatch('group/query')
    if (!this.$store.state.group.group_orderList.length) await this.$store.dispatch('group/queryGroupOrder')
    if (address) {
      this.$store.dispatch('group/changeFocusProject', address)
    }
  },
  data() {
    return {
      ProductFields,
      field
    }
  },
  computed: {
    ...mapState('group', ['group_projectList']),
    ...mapGetters('group', ['focusProject', 'focusProjectOrders', 'focusProjectOrderDict']),
    ...mapGetters('product', ['productDict', 'productUidDict']),
    ...mapGetters('layout', ['layoutDict']),
    projectProducts() {
      if (this.focusProject && this.$store.state.product.productList.length) return this.focusProject.group_project_products.map(product => ({
        ...this.productDict[product.product_id],
        group_product_price: product.price,
        group_product_set_quantity: product.set_quantity
      }))
      return []
    },
    groupProductSales() {
      if (this.focusProjectOrders.length) {
        return this.focusProjectOrders.reduce((acc, cur) => {
          return cur.group_order_product.reduce((ac, cu) => {
            return {
              ...ac,
              [cu.product_id]: (ac[cu.product_id] || 0) + cu.product_quantity
            }
          }, acc)
        }, this.focusProject.group_project_products.reduce((acc, p) => ({ ...acc, [p.product_id]: 0 }), {}))
      }
      return {}
    }
  },
  methods: {
    ...mapActions('group', ['updateGroupOrderState']),
    timestampParse,
    ImgError(event) {
      event.target.src = "https://admin.chris-farrell.com.tw/static/product_default_img.png";
      return true;
    },
    genDoc(order) {
      console.log(order);
      const templateInst = new Template()
      const subtotal = [order.group_order_product, { product_id: '18', product_quantity: 1, product_price: 0 }].reduce((acc, cur) => {
        const product = this.productDict[cur.product_id]
        return acc + (product.product_price === 0 ? 0 : 500 * cur.product_quantity)
      }, 0)
      const freight = parseInt(subtotal) >= 1000 ? '0' : 70
      console.log(freight);
      const orderItems = [order.group_order_product, { product_id: '18', product_quantity: 1, product_price: 0 }].reduce((acc, cur) => {
        const product = this.productDict[cur.product_id]
        return acc + `<tr>
          <td>${product.product_uid}</td>
          <td>${product.product_name_zh}</td>
          <td>${ cur.product_price === 0 ? '贈' : 'NT$' + 500 }</td>
          <td>${cur.product_quantity}</td>
          <td>${cur.product_price === 0 ? '贈' : 'NT$' + 500 * cur.product_quantity}</td>
        </tr>`
      }, "") + Array(8).fill('<tr></tr>').join('')
      templateInst.assign({
        orderId: `G-${order.group_order_uid}`,
        receiverName: order.group_order_name,
        receiverPhone: order.group_order_phone,
        cvsType: order.group_order_logistics_info.LogisticsSubType === 'UNIMARTC2C' ? '7-11 統一超商' : 'FamilyMart全家',
        booknote: order.group_order_logistics_info.CVSPaymentNo,
        portTime: moment().format('YYYY/MM/DD'),
        amount: order.group_order_amount,
        subtotal,
        freight,
        orderItems
      })
      return templateInst.display(GROUP_PDF_TEMPLATE)
      
    },
    printDoc(order) {
      const wnd = window.open("about:blank", "", "_blank")
      wnd.document.write(this.genDoc(order))
      wnd.focus()
      setTimeout(() => wnd.print(), 500)
    },
    handleCheck(orders) {
      const orderDocs = orders.map(order => this.genDoc(order)).join('')
      const wnd = window.open("about:blank", "", "_blank")
      wnd.document.write(orderDocs)
      wnd.focus()
      setTimeout(() => wnd.print(), 500)
    },
    handlePort(order) {
      this.$confirm.info({
        title: "確定要將此訂單標記為『已出貨』嗎",
        icon: "truck",
        callback: async (ensure) => {
          if (ensure) {
            const res = await this.updateGroupOrderState(order.group_order_id)
            if (res) this.$message.success('已將訂單標記為出貨')
          }
        }
      })
      
    }
  },
  components: {
    KWidgetIcon, KCard, KTable, KTag, KButton
  }
}

const field = [
  { key: 'group_order_name', fieldIndex: 'group_order_name', label: '收件人', searchable: true },
  { key: 'group_order_phone', fieldIndex: 'group_order_phone', label: '電話', searchable: true },
  { key: 'group_order_amount', fieldIndex: 'group_order_amount', label: '訂單總額', sorter: (a, b) => a - b },
  { key: 'group_order_logistics_info', fieldIndex: 'group_order_logistics_info', label: '取貨超商', filters: [
    { text: '7-11 統一超商', value: 'LogisticsSubType:UNIMARTC2C' },
    { text: 'Family 全家', value: 'LogisticsSubType:FAMIC2C' },
  ] },
  { key: 'group_order_state', fieldIndex: 'group_order_state', label: '出貨狀態', filters: [
    { text: '已出貨', value: true },
    { text: '未出貨', value: false },
  ] },
  { key: 'group_order_create_at', fieldIndex: 'group_order_create_at', label: '下單日期', sorter: (a, b) => b - a },
  { key: 'action', fieldIndex: 'action', label: ''},
]

const ProductFields = [
  { key: 'product_uid', fieldIndex: 'product_uid', label: '貨號' },
  { key: 'file_path', fieldIndex: 'file_path', label: '產品首圖' },
  { key: 'product_name_zh', fieldIndex: 'product_name_zh', label: '產品名稱', sorter: (a, b) => ('' + a).localeCompare(b), searchable: true },
  { key: 'group_product_price', fieldIndex: 'group_product_price', label: '團購價格'},
  { key: 'product_type', fieldIndex: 'product_type', label: '產品類型', filters: [
    { value: 'PRODUCT', text: '單品' },
    { value: 'SET', text: '組合' },
  ] },
  { key: 'group_product_sales', fieldIndex: 'group_product_sales', label: '銷量' }
]
</script>
