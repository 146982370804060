<template>
  <div class="k-layout-basic">

    <k-sidebar :menus="sidebarMenus" :currentRoute="currentRoute">
      <template #brand>
        <k-icon icon="x-diamond-fill" />CHRIS FARRELL
      </template>
    </k-sidebar>

    <section class="k-wraper">
      <k-header />
      <slot></slot>
    </section>

  </div>
</template>

<script>
import { KSidebar, KHeader } from './components';

export default {
  async mounted() {
    const username = await this.$store.dispatch('email/checkLoginState')
    console.log(username);
    if (username) await this.$store.dispatch('email/queryEmailTags', username)
    this.$store.dispatch('layout/query')
    this.$store.dispatch('coupon/query')
    this.$store.dispatch('posts/query')
    this.$store.dispatch('product/query')
    this.$store.dispatch('edm/query')
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      sidebarMenus: menus,
      currentRoute: null
    }
  },
  watch: {
    $route: {
      handler(newRoute) {
        console.log(newRoute);
        this.currentRoute = newRoute.path
      },
      immediate: true
    }
  },
  components: {
    KSidebar,
    KHeader
  }
}

const menus = [
  {
    type: 'title',
    label: 'BLOG'
  },
  {
    type: 'submenu',
    label: '文章管理',
    icon: 'gear',
    children: [
      {
        type: 'item',
        to: '/blog',
        label: '所有文章',
        icon: 'file-earmark'
      },
      {
        type: 'item',
        to: '/blog/create',
        label: '建立文章',
        icon: 'pencil'
      },
      {
        type: 'item',
        to: '/blog/category',
        label: '管理分類',
        icon: 'diagram-3'
      },
    ]
  },
  {
    type: 'submenu',
    label: 'SEO 設定',
    icon: 'search',
    children: [
      {
        type: 'item',
        to: '/seo',
        label: '管理模板',
        icon: 'files'
      },
      {
        type: 'item',
        to: '/seo/create',
        label: '建立模板',
        icon: 'file-plus'
      },
    ]
  },
  {
    type: 'submenu',
    label: '問卷表單管理',
    icon: 'search',
    children: [
      {
        type: 'item',
        to: '/questionnaire',
        label: '所有表單',
        icon: 'ui-checks'
      },
      {
        type: 'item',
        to: '/questionnaire/create',
        label: '建立表單',
        icon: 'file-plus'
      },
    ]
  },
  {
    type: 'title',
    label: 'MEDIA'
  },
  {
    type: 'item',
    to: '/media',
    label: '多媒體庫',
    icon: 'play-btn-fill'
  },
  {
    type: 'title',
    label: 'PRODUCT'
  },
  {
    type: 'submenu',
    label: '產品管理',
    icon: 'cart-fill',
    children: [
      {
        type: 'item',
        to: '/product',
        label: '所有產品',
        icon: 'list-ul'
      },
      {
        type: 'item',
        to: '/product/create',
        label: '建立產品',
        icon: 'file-plus'
      },
    ]
  },
  {
    type: 'title',
    label: 'CRM SYSTEM'
  },
  {
    type: 'submenu',
    label: '電商管理',
    icon: 'shop',
    children: [
      {
        type: 'item',
        to: '/e-commerce',
        label: '統計面板',
        icon: 'graph-up'
      },
      {
        type: 'item',
        to: '/user',
        label: '官網會員',
        icon: 'person-lines-fill'
      },
      {
        type: 'item',
        to: '/e-commerce-order',
        label: '電商訂單',
        icon: 'cash-coin'
      },
      {
        type: 'item',
        to: '/group',
        label: '團購管理',
        icon: 'bag-plus-fill'
      },
      {
        type: 'item',
        to: '/e-commerce-coupon',
        label: '優惠券管理',
        icon: 'ticket-perforated'
      },
      {
        type: 'item',
        to: '/e-commerce-edm',
        label: 'EDM 管理',
        icon: 'envelope'
      }
    ]
  },
  {
    type: 'submenu',
    label: '經銷管理',
    icon: 'building',
    children: [
      {
        type: 'item',
        to: '/dealer',
        label: '統計面板',
        icon: 'graph-up'
      },
      {
        type: 'item',
        to: '/dealer-list',
        label: '經銷商列表',
        icon: 'person-lines-fill'
      },
      {
        type: 'item',
        to: '/dealer-order',
        label: '經銷訂單',
        icon: 'list-ul'
      }
    ]
  },
  {
    type: 'item',
    to: '/inventory',
    label: '存貨管理',
    icon: 'box-seam'
  },
  {
    type: 'item',
    to: '/cost-list',
    label: '成本項目',
    icon: 'currency-exchange'
  }
]
</script>
