<template>
  <k-container class="view-group-list">
    <header class="pageTitle">
      <h1>所有團購</h1>
      <k-button theme="info" icon="plus-lg" @click="handleShowCreate">建立團購</k-button>
    </header>

    <div class="group-container">
      <template v-if="group_projectList.length && Object.keys(productDict).length">
        <k-widget-icon
          v-for="project in group_projectList"
          :key="`group_project_${project.key_id}`"
          icon="bag-plus-fill"
          :theme="inPeriod(project)"
          :header="project.group_project_name"
          @click="e => toProject(e, project.group_project_address)"
        >
          <div class="content">
            <p>/{{ project.group_project_address }}</p>
            <p>{{ project.group_project_start | parseToDate }} - {{ project.group_project_end | parseToDate }}</p>
            <a :href="`https://chris-farrell.com.tw/group/${project.group_project_address}`" target="_blank">
              <k-icon icon="box-arrow-up-right" />
            </a>
          </div>
        </k-widget-icon>
      </template>
    </div>

    <k-modal
      :show.sync="showCreateModal"
      title="建立團購"
      :okButtonProps="{ theme: 'info', loading: createLoading, label: '建立' }"
      @cancel="handleCancel"
    >
      <form>
        <k-input label="團購網址" v-model="createForm.group_project_address" addonBefore="https://www.chris-farrell.com.tw/group/"></k-input>
        <k-input label="團購名稱" v-model="createForm.group_project_name"></k-input>
        <k-range-picker label="開團日期" v-model="createForm.group_project_time"></k-range-picker>
        <k-divider>選擇團購商品</k-divider>
        <k-card class="product-choose" outline="light">
          <k-card>
            <k-button
              v-for="product in productList.filter(p => unchoosedItem(p))" :key="`group-product-unchoosed-${product.key_id}`" theme="light" block
              @click="handleChooseProduct(product.key_id)"
            >
              {{ product.product_name_zh }}
            </k-button>
          </k-card>
          <k-icon icon="caret-right-fill"></k-icon>
          <k-card class="choosed-items">
            <ul v-if="productList.length">
              <li v-for="choosedProduct in createItems" :key="`group-product-choosed-${choosedProduct.product_id}`">
                <header>
                  <b>{{ productDict[choosedProduct.product_id].product_name_zh }} | ${{ productDict[choosedProduct.product_id].product_price.MEMBER || 0 }}</b>
                  <div class="remove"><k-icon icon="x-lg" @click="handleRemoveProduct(choosedProduct.product_id)" /></div>
                </header>
                <k-input type="Number" addonBefore="團購價" v-model="choosedProduct.price"></k-input>
              </li>
            </ul>
          </k-card>
        </k-card>
      </form>
    </k-modal>
  </k-container>
</template>

<script>
import { KWidgetIcon, KWidgetImage } from '@/components/Widget'
import { mapGetters, mapState } from 'vuex'
import KCard from '@/components/Card'
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import KDivider from '@/components/Divider'
import { KInput, KRangePicker } from '@/components/Form'
import { timestampParse } from '@/utils'
import moment from 'moment'

export default {
  async mounted() {
    this.$store.dispatch('group/query')
  },
  data() {
    return {
      showCreateModal: false,
      createLoading: false,
      createForm: {
        group_project_address: '',
        group_project_name: '',
        group_project_time: ''
      },
      createItems: []
    }
  },
  computed: {
    ...mapState('group', ['group_projectList']),
    ...mapState('product', ['productList']),
    ...mapGetters('product', ['productDict']),
  },
  methods: {
    timestampParse,
    inPeriod(project) {
      const now = moment().format('YYYY-MM-DD')
      let start = timestampParse(project.group_project_start)
      let end = timestampParse(project.group_project_end)
      if (start.isAfter(now)) return 'info'
      if (end.isBefore(now)) return 'light'
      return 'success'
    },
    toProject(e, address) {
      console.log(e.target);
      if (e.target.tagName === 'A' || e.target.tagName === 'I') return
      this.$router.push(`/group/${address}`)
    },
    unchoosedItem(product) {
      return !this.createItems.find(item => item.product_id === product.key_id)
    },
    handleShowCreate() {
      this.showCreateModal = true
    },
    handleChooseProduct(product_id) {
      const { product_price } = this.productDict[product_id]
      this.createItems.push({ product_id, price: product_price.MEMBER, set_quantity: 1 })
    },
    handleRemoveProduct(product_id) {
      this.createItems = this.createItems.filter(item => item.product_id !== product_id)
    },
    handleCancel: void 8
  },
  filters: {
    parseToDate(timestamp) {
      return timestampParse(timestamp).format('YYYY/MM/DD')
    }
  },
  components: {
    KWidgetIcon, KWidgetImage, KButton, KModal, KInput, KRangePicker, KDivider, KCard
  }
}
</script>
