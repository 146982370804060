import { fetchApi } from '../index'
import md5 from 'blueimp-md5'

export function genCheckMacValue(formData) {
  const transform = Object.entries(formData).sort((a, b) => b[0] - a[0])
  const hash = [
    ['HashKey', process.env.VUE_APP_ECPAY_LOGISTICS_C2C_HASHKEY],
    ...transform,
    ['HashIV', process.env.VUE_APP_ECPAY_LOGISTICS_C2C_HASHIV]
  ].map(e => `${e[0]}=${e[1]}`).join('&')
  const urlEncodedData = encodeURIComponent(hash).toLowerCase().replace(/%20/g, '+')
  return md5(urlEncodedData).toUpperCase()
}

export async function createReverseLogisticsOrder(order) {
  const API = '/Express/ReturnHome'
  const { AllPayLogisticsID } = order.order_logistics_info
  const shippingInfo = {
    AllPayLogisticsID,
    GoodsAmount: order.order_amount,
    GoodsName: '保養品',
    Distance: '00',
    Specification: '0001',
    SenderName: order.order_return_info.SenderName,
    SenderCellPhone: order.order_return_info.SenderCellPhone,
    SenderZipCode: order.order_return_info.SenderZipCode,
    SenderAddress: order.order_return_info.SenderAddress,
    ScheduledPickupTime: '4',
    ScheduledDeliveryTime: '4',
    ServerReplyURL: 'https://api.chris-farrell.com.tw/index.php/input/call',
    ReceiverName: '顏爾佑',
    ReceiverPhone: '0988879595',
    ReceiverZipCode: '237021',
    ReceiverAddress: '新北市三峽區大觀路168號13樓',
    ReceiverEmail: 'info@chris-farrell.com.tw',
    LogisticsSubtype: 'TCAT'
  }
  const res = await fetchApi('/api/ecpay/', {
    api: API,
    shippingInfo
  })
  console.log(res);
  return res
}

export const LOGISTICS_SUBTYPE_DICT = {
  TCAT: '黑貓宅配',
  UNIMARTC2C: '7-11 統一超商',
  FAMIC2C: '全家 FamilyMart'
}
