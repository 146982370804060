<template>
  <div :class="{ 'k-editor': true, 'invalid': isInvalid }">
    <label v-if="label"><span v-if="required && !hideRequiredMark">＊ </span>{{ label }}</label>
    <input
      v-if="required"
      type="text"
      :value="value.html"
      @invalid="handleInvalid"
      required
    >
    <editor
      :api-key="apiKey"
      :init="{ ...init, height: `${height}px` }"
      cloud-channel="5-dev"
      :initial-value="value.html"
      plugins=""
      v-model="editorValue.html"
    />
    <transition name="fade">
      <div
        class="editor-loading"
        :style="{ height: `${height || 600}px` }"
        v-if="!isInit"
      >
        <k-icon icon="arrow-clockwise"></k-icon>
        loading...
      </div>
    </transition>
    <span
      v-if="isInvalid"
      class="extra invalid"
    >
      <k-icon
        icon="exclamation-triangle"
        theme="danger"
      />本欄位為必填，請勿留空
    </span>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import formMixin from '@/mixin/form'

export default {
  mixins: [formMixin],
  props: {
    label: String,
    required: Boolean,
    hideRequiredMark: Boolean,
    defaultValue: String,
    height: [Number, String],
    value: {
      type: Object,
      default: () => ({ html: '', fileList: [] })
    },
    fileList: Array
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
      isInit: false,
      init: {
        content_style: `
          img {
            border-radius: 4px;
          }

          .section-header {
            display: inline-block;
            border-radius: 4px;
            padding: 0 8px;
            background-color: #607d8b9c;
          }
          .section-header:before, .section-header:after{
            content: " ― ";
          }
          .chapter-header {
            background: #ddd;
          }
          h1, h2, h3, h4, h5, h6 {
            margin: 4px 0;
          }
          p, b, i, span {
            margin: 0;
            font-size: 14px;
          }
          blockquote {
            font-family: 'Andale Mono', 'Courier New', monospace;
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: .1em;
            position: relative;
            border: 3px solid #ddd;
            padding: 4em 2.5rem;
            margin: calc(2.5em - 16px) auto 2.5em !important;
            text-align: left;
            max-width: 320px;
            text-align: center;
            box-sizing: border-box;
            box-shadow: 13px 13px 0 0 #2a2c36 inset, 16px 16px 0 0 #607d8b;
            background-color: #607d8b;
            color: #222;
          }

          blockquote::before {
            content: '“';
            font-size: 5em;
            color: #222;
            position: absolute;
            top: -.05em;
            left: 50%;
            transform: translateX(-50%);
            font-family: 'georgia';
            pointer-events: none;
          }

          blockquote::after {
            content: '“';
            font-size: 5em;
            color: #222;
            position: absolute;
            bottom: -.55em;
            left: 50%;
            transform: translateX(-50%);
            font-family: 'georgia';
            pointer-events: none;
          }

          blockquote > * {
            margin: .75em 0;
          }

          blockquote cite {
            display: block;
            font-style: normal;
            font-size: 12px;
          }
        `,
        init_instance_callback: (inst) => this.editorInit(inst),
        fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px",
        skin_url: './kirsty-dark',
        plugins: 'autolink lists media table image link fullscreen preview code',
        toolbar: ['undo redo formatPainter | styleselect fontsizeselect | bold italic underline strikethrough blockquote | \
                  forecolor backcolor',
          'markHeadline markCapterHeader link image | alignleft aligncenter alignright alignjustify | bullist \
                  numlist | outdent indent | removeformat | table fullscreen preview | code'],
        toolbar_mode: 'floating',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        height: '600px',
        automatic_uploads: true,
        file_picker_types: 'image',
        images_upload_handler: (blobInfo, success, failure) => {
          const logFileInfo = (file, previewSrc) => {
            if (typeof file.name === 'string') this.fileUploadLog({ file, previewSrc });
          }
          try {
            var reader = new FileReader();
            reader.onload = el => {
              logFileInfo(blobInfo.blob(), el.target.result);
              success(el.target.result, { alt: 'My alt text' });
            }
            reader.readAsDataURL(blobInfo.blob());
          } catch (error) {
            failure(error);
          }
        },
        setup: editor => {
          editor.ui.registry.addButton('markHeadline', {
            icon: 'bookmark',
            tooltip: '將選取文字標記為目錄段落',
            onAction: () => {
              const target = editor.selection.getNode()
              if (target.classList.contains("section-header")) target.removeAttribute('id')
              else target.id = target.textContent.split(' ').join('-')
              target.classList.toggle("section-header")
              this.editorValue.html = editor.getContent()
            }
          })
          editor.ui.registry.addButton('markCapterHeader', {
            icon: 'flip-vertically',
            tooltip: '將選取段落標示為章首',
            onAction: () => {
              const target = editor.selection.getNode()
              const content = editor.selection.getContent()
              if (target.classList.contains('chapter-header')) {
                const fragment = document.createDocumentFragment()
                Array.from(target.children).forEach(ele => fragment.appendChild(ele))
                target.replaceWith(fragment)
              } else {
                editor.selection.setContent(`<div class="chapter-header">${content}</div>`)
              }
              this.editorValue.html = editor.getContent()
            }
          })
          editor.ui.registry.addToggleButton('formatPainter', {
            icon: 'format-painter',
            tooltip: '複製特定區段的文字格式到點選的區段',
            onAction: (api) => {
              if (this.formatPrinterAttrs === null) {
                api.setActive(true);
                const target = editor.selection.getNode()
                const clone = target.cloneNode(true);
                this.formatPrinterAttrs = event => {
                  const inner = event.target.innerHTML
                  const cloneElement = clone.cloneNode(true);
                  cloneElement.innerHTML = inner
                  event.target.parentNode.replaceChild(cloneElement, event.target);
                }
                editor.dom.doc.body.addEventListener('mouseup', this.formatPrinterAttrs)
              } else {
                api.setActive(false);
                editor.dom.doc.body.removeEventListener('mouseup', this.formatPrinterAttrs)
                this.formatPrinterAttrs = null
              }
            }
          })
        }
      },
      editorValue: { html: '', fileList: [] },
      formatPrinterAttrs: null
    }
  },
  methods: {
    fileUploadLog(file) {
      this.editorValue.fileList = [...this.editorValue.fileList, file]
    },
    editorInit(inst) {
      if (this.defaultValue) inst.setContent(this.defaultValue)
      this.$emit('init')
      this.isInit = true
    }
  },
  watch: {
    editorValue: {
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue);
      }
    },
  },
  components: {
    'editor': Editor
  }
}
</script>
