var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-container',{staticClass:"view-group-focus"},[_c('k-widget-icon',{attrs:{"theme":"success","icon":"bag-plus-fill","header":_vm.focusProject ? _vm.focusProject.group_project_name : '',"text":_vm.focusProject ? _vm.timestampParse(_vm.focusProject.group_project_start).format('YYYY/MM/DD') + ' - ' + _vm.timestampParse(_vm.focusProject.group_project_end).format('YYYY/MM/DD') : ''}}),_c('k-card',{staticClass:"group-products",attrs:{"header":"團購商品","collapsible":""}},[_c('k-table',{attrs:{"items":_vm.projectProducts,"fields":_vm.ProductFields,"hover":""},scopedSlots:_vm._u([{key:"cell(file_path)",fn:function(ref){
var item = ref.item;
return [(item.product_type === 'PRODUCT')?_c('img',{attrs:{"src":("https://chris-farrell.com.tw/img/product/" + (item.product_uid) + "/" + (_vm.layoutDict['PRODUCT_AVATAR_NAME'].layout_content || 'avatar') + ".webp")},on:{"error":function (e) { return _vm.ImgError(e); }}}):_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":("https://chris-farrell.com.tw/img/product/" + (item.product_images[0]))}})])]}},{key:"cell(product_name_zh)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(_vm._s(item.product_name_en)),_c('br'),_vm._v(_vm._s(value))]}},{key:"cell(group_product_price)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('p',{staticClass:"member-price"},[_vm._v("$"+_vm._s(item.product_price.MEMBER))]):_vm._e(),_c('p',[_vm._v(_vm._s(value ? ("$" + value) : '贈品'))])]}},{key:"cell(product_type)",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value==='PRODUCT' ? '單品' : '組合'))]}},{key:"cell(group_product_sales)",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.groupProductSales[item.product_id] || '-'))]}}])})],1),_c('k-card',{attrs:{"header":"團購訂單"}},[_c('k-table',{attrs:{"items":_vm.focusProjectOrders,"fields":_vm.field,"rowSelection":true,"hover":""},on:{"check":_vm.handleCheck},scopedSlots:_vm._u([{key:"cell(group_order_amount)",fn:function(ref){
var value = ref.value;
return [_vm._v(" $ "+_vm._s(value)+" ")]}},{key:"cell(group_order_logistics_info)",fn:function(ref){
var value = ref.value;
return [_c('k-tag',{attrs:{"theme":value.LogisticsSubType === 'UNIMARTC2C' ? 'success' : 'info'}},[_vm._v(" "+_vm._s(value.LogisticsSubType === 'UNIMARTC2C' ? '7-11 統一超商' : 'FamilyMart全家')+" ")])]}},{key:"cell(group_order_state)",fn:function(ref){
var value = ref.value;
return [_c('k-tag',{attrs:{"theme":value ? 'primary' : 'secondary'}},[_vm._v(_vm._s(value ? '已出貨' : '未出貨'))])]}},{key:"cell(group_order_create_at)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.timestampParse(value).format('YYYY-MM-DD HH:mm'))+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"button-group"},[_c('k-button',{attrs:{"varient":"ghost","theme":"info"},on:{"click":function($event){return _vm.printDoc(item)}}},[_vm._v("列印")]),_c('k-button',{attrs:{"varient":"ghost","theme":"warning"},on:{"click":function($event){return _vm.handlePort(item)}}},[_vm._v("出貨")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }