<template>
  <div id="app">
    <transition name="fade">
      <section v-if="loading && loginStateLoading" class="fullpage-loading">
        <k-loader-scale></k-loader-scale>
        <h1 style="color: #fff;">loading...</h1>
      </section>

      <template v-if="!loading && !loginStateLoading">
        <router-view v-if="['Login', 'Active'].includes($route.name)" :key="$route.fullPath"/>
        <k-layout-basic v-else >
          <router-view :key="$route.path"/>
        </k-layout-basic>  
      </template>
    </transition>
  </div>
</template>

<script>
import { KLayoutBasic } from '@/layouts';
import { KLoaderScale } from '@/components/Loader'
import { mapState } from 'vuex'

export default {
  async mounted() {
    this.$store.dispatch('login/hasLogin')
    this.$router.onReady(() => {
      setTimeout(() => {
        this.loading = false
      }, 1000);
    })
  },
  data() {
    return {
      loading: true,
      loginStateLoading: false
    }
  },
  computed: {
    ...mapState('login', { loginState: state => state.loginState }),
  },
  watch: {
    loginState: {
      immediate: true,
      handler(newVal) {
        if (newVal !== null) {
          this.loginStateLoading = false
        }
        if (newVal === false && !['Login', 'Active'].includes(this.$route.name)) {
          this.$router.replace('/login')
        }
      } 
    }
  },
  components: {
    KLayoutBasic, KLoaderScale
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
