<template>
  <k-container class="view-edit-container" :title="`${mode === 'CREATE' ? '撰寫' : '修改'}文章`">
    <k-form v-if="!loading" :questionConfig.sync="questionConfig" :defaultValues="defaultValues" @submit="handleSubmit">
      <section class="media-library" slot="after(post_summary)">
        <k-dropdown placement="rightBottom" ref="media-dropdown">
          <k-button theme="light" varient="outline">從圖片庫選擇</k-button>
          <k-card slot="overlay">
            <k-menu v-if="postImages.length">
              <k-menu-item v-copy="image.file_path" v-copy:success="onCopy" v-for="image in postImages" :key="image.id">
                <img  :src="image.file_path" :alt="image.file_name">
                <p>{{ image.file_name }}</p>
              </k-menu-item>
            </k-menu>

            <k-menu v-else>
              <k-menu-item v-for="e, index in Array(5).fill()" :key="`skeloton_${index}`">
                <k-skeleton :loading="true" :line="0" active dense />
              </k-menu-item>
            </k-menu>
          </k-card>
        </k-dropdown>
      </section>

      <Setting
        slot="after(post_content)"
        ref="seo"
        @mutate="() => seoMutated = true"
        :originalTemplateUse="mode === 'UPDATE' ? defaultValues.seo_template_id : null"
        :originalStructureData="mode === 'UPDATE' 
          ? defaultValues.post_structured_data && JSON.parse(defaultValues.post_structured_data)
          : null"
      />
    </k-form>

  </k-container>
</template>

<script>
import { KForm, KInput, KSwitch, KDatePicker, KUpload } from '../../components/Form'
import { KMenu, KMenuItem, KMenuDivider } from '@/components/Menu'
import KDropdown from '../../components/Dropdown'
import KCard from '../../components/Card'
import KButton from '../../components/Button'
import KSkeleton from '@/components/Skeleton'
import Setting from '@/views/seo/components/Setting'
import { timestampParse, fetchApi } from '../../utils'
import { mapState, mapGetters, mapActions } from 'vuex'
import database from '@/utils/database'
import storage from '@/utils/storage'

export default {
  async mounted() {
    this.$store.dispatch('media/query')
    if (!this.$store.state.posts.categoryList.length) await this.$store.dispatch('posts/queryCategories')
    if (this.$route.params.id) {
      this.updateId = this.$route.params.id
      if (!this.$store.state.posts.postList.length) await this.$store.dispatch('posts/query')
      const response = await database.table('file')
        .join('file_use', 'file_id')
        .where('file_use.post_id', '=', this.updateId).get()
      console.log(response);
      this.fileUseInPost = [ ...response.data ]
      this.insertUpdatePostDataIntoForm(this.updateId)
      this.loading = false
    } else {
      this.loading = false
    }
    
  },
  data() {
    return {
      loading: true,
      defaultValues: {},
      questionConfig: genQuestions(this.handleFileChange, this.categoryOptions, 'CREATE'),
      imgSrc: null,
      bannerFile: null,
      updateId: null,
      unuseFileIds: [],
      fileUseInPost: [],
      seoMutated: false
    }
  },
  methods: {
    ...mapActions('media', ['insertFileInfo', 'registerFileUse', 'unlinkFileUse']),
    ...mapActions('posts', ['add', 'update', 'createStructureData']),

    onCopy() {
      this.$message.success('已成功複製圖片路徑至剪貼簿！')
      this.$refs['media-dropdown'].hide()
    },
    handleFileChange(info) {
      if (this.mode === 'UPDATE') {
        const oldBannerId = this.mediaList.filter(m=>m.name === this.defaultValues.bannerName)[0]['id']
        this.unuseFileIds.push(oldBannerId)
      }
      this.questionConfig[0].imgSrc = info.src
    },
    insertUpdatePostDataIntoForm(id) {
      const postIndex = this.postList.map(p => p.key_id).indexOf(id)
      const post = this.postList[postIndex]
      const { post_bannerPath, post_publishAt } = post
      this.defaultValues = { 
        ...post, post_banner: post_bannerPath, post_publishAt, post_publishAt: timestampParse(post_publishAt)
      }
    },
    compareImgUnuse(newHtml) {
      const DOM = new DOMParser().parseFromString(`<body>${newHtml}</body>`, "text/html")
      const newImgNames = Array.from(DOM.getElementsByTagName('img')).map(e => e.getAttribute('src').split('/').slice(-1)[0])
      const unuseFileIds = [
        ...this.unuseFileIds,
        ...this.oldPostImgs.filter(om => !newImgNames.includes(om.file_name)).map(om => om.key_id)
      ]
      const newChooseFileIds = this.postImages
        .filter(img => newImgNames.includes(img.file_name) && !this.oldPostImgs.map(e => e.key_id).includes(img.key_id))
        .map(e => e.key_id)
      return { unuseFileIds, newChooseFileIds }
    },
    async logFileUse(postId, fileIdList) {
      const API = '/api/file_use/'
      const json = await fetchApi(API, { postId, fileIdList, useType: 'post' })
      return json
    },
    async uploadFileList(fileList) {
      const filePathList = await Object.entries(fileList).reduce(async (acc, [column, files]) => {
        var accThen = await acc
        if (Array.isArray(files)) {
          accThen[column] = await files.reduce(async (accumulator, f) => {
            const storageRes = await storage.path('img/post').put(f)
            const file_id = await this.insertFileInfo(storageRes)
            const accumulatorThen = await accumulator
            accumulatorThen.push({ file_id, ...storageRes })
            return accumulatorThen
          },[])
          return accThen
        }
        const storageRes = await storage.path('img/post').put(f)
        const file_id = await this.insertFileInfo(storageRes)
        accThen[column] = { file_id, ...storageRes }
        return accThen
      }, {})

      return filePathList
    },
    async handleSubmit(formData) {
      if (formData) {
        const { values, fileList } = formData
        if (Object.keys(fileList).length) {
          var fileRes = await this.uploadFileList(fileList)
          if (fileRes.post_banner && fileRes.post_banner.length) {
            const { file_path, file } = fileRes.post_banner[0]
            values.post_bannerPath = file_path
            values.post_banner = file.name
          }
        }

        values.post_publishAt = values.post_publishAt.unix()
        let stateInfo;
        if (this.mode === 'CREATE') stateInfo = await this.add(values)
        if (this.mode === 'UPDATE') stateInfo = await this.update({ id: this.updateId, ...values })

        if (fileRes && Object.keys(fileRes).length) {
          const allFileInfo = Object.values(fileRes).reduce((acc, fileArray) => [...acc, ...fileArray], [])
          allFileInfo.forEach(f => this.registerFileUse({ file_id: f.file_id, post_id: stateInfo.id || this.updateId }))
        }

        const { unuseFileIds, newChooseFileIds } = this.compareImgUnuse(formData.values.post_content)
        if (this.mode === 'UPDATE' && unuseFileIds.length) {
          await this.unlinkFileUse({
            useType: 'post',
            useTypeId: this.updateId,
            fileIds: [ ...this.unuseFileIds ],
            keepFile: true
          })
        }
        if (newChooseFileIds.length) {
          newChooseFileIds.forEach(f => this.registerFileUse({ file_id: f, post_id: stateInfo.id || this.updateId }))
        }

        if (this.seoMutated) {
          const { templateUse: seo_template_id, data } = this.$refs.seo.getData()
          await this.createStructureData({ 
            id: stateInfo.id || this.updateId,
            post_structured_data: JSON.stringify(data),
            seo_template_id,
          })
        }

        this.$router.push('/blog')
      }
    }
  },
  computed: {
    ...mapState('posts', ['postList', 'categoryList']),
    ...mapState('media', ['mediaList']),
    ...mapGetters('posts', ['currentPost']),
    ...mapGetters('media', ['filterByMediaType']),
    mode: {
      get() {
        const mode = this.$route.params.id ? 'UPDATE' : 'CREATE'
        this.mode = mode
        return mode
      },
      set(newVal) {
        this.questionConfig = genQuestions(this.handleFileChange, this.categoryOptions, newVal)
      }
    },
    categoryOptions() {
      if (this.categoryList.length) return this.categoryList.map(e => ({ value: e.key_id, text: e.category_name }))
    },
    postImages() {
      if (this.filterByMediaType.image) {
        return this.filterByMediaType.image
      }
      return []
    },
    oldPostImgs() {
      if (this.defaultValues.post_content) {
        const DOM = new DOMParser().parseFromString(`<body>${this.defaultValues.post_content}</body>`, "text/html")
        const imgs = Array.from(DOM.getElementsByTagName('img')).map(e => {
          let imgPath = e.getAttribute('src')
          console.log(imgPath.split('/').slice(-1)[0]);
          return this.mediaList.filter(m => m.file_name === imgPath.split('/').slice(-1)[0])[0]
        })
        return imgs
      }
      return []
    }
  },
  components: {
    KForm, KInput, KSwitch, KDatePicker, KUpload, KButton, KDropdown, KCard, KMenu, KMenuItem, KMenuDivider, KSkeleton,
    Setting
  }
}

const genQuestions = (handleFileChange, categoryOptions ,mode) => [
  {
    questionType: 'k-upload',
    name: 'post_banner', 
    imgSrc: null,
    alt: 'banner',
    label: 'Banner',
    required: mode === 'CREATE' ? true : false,
    droppable: true,
    block: true,
    onChange: info => handleFileChange(info)
  },
  {
    questionType: 'k-input',
    name: 'post_title',
    label: "文章標題",
    theme: "light",
    required: true,
    title: '請輸入文章標題'
  },
  {
    questionType: 'k-date-picker',
    name: 'post_publishAt',
    label: "上架時間",
    theme: 'light',
    required: true,
  },
  {
    questionType: 'k-switch',
    name: 'post_isPublished',
    label: "預設為發佈狀態",
    theme: "warning",
    required: true,
  },
  {
    questionType: 'k-select',
    name: 'category_id',
    label:"文章類別",
    options: categoryOptions,
    required: true,
    theme: 'light',
    placeholder: '請選擇文章類別'
  },
  {
    questionType: 'k-textarea',
    name: 'post_summary',
    label: '總結描述',
    required: true,
    maxlength: 140,
    placeholder: '請以最多140字描述本篇文章的重點',
    extra: '此總結會利用於SEO搜尋使用',
    theme: 'light'
  },
  {
    questionType: 'k-editor',
    name: 'post_content',
    label: "內文",
    required: true,
    filePath: './img/post',
    height: 600
  },
  {
    questionType: 'k-btn',
    label: mode === 'CREATE' ? "完成建立" : "完成修改",
    theme: "primary",
    type: "submit"
  }
]
</script>
