import database from '@/utils/database'
import { genBasicModel } from './utils/basicAction'
import { fetchApi } from '../utils'

const MODEL_NAME = 'email_template'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query)

async function query() {
  return await database.table(MODEL_NAME).get()
}

export default {
  namespaced: true,
  state: {
    email_templateList: [],
    email_historyList: []
  },
  mutations: {
    ...basicMutation,
    addHistory(state, payload) {
      state.email_historyList = [{
        ...payload,
        email_history_recipients: JSON.parse(payload.email_history_recipients)[0],
        email_history_info: payload.email_history_info ? JSON.parse(payload.email_history_info) : {},
      }, ...state.email_historyList]
    }
  },
  actions: {
    ...basicAction,
    async sendEmail({ commit, state, getters }, payload) {
      const  { subject, systemId, content, recipients, user_id, email_info } = payload
      console.log(getters);
      const res = await fetchApi('/api/surenotify/send/', {
        data: {
          subject: systemId ? getters.edmSystemIdDict[systemId].email_template_subject : subject,
          content: systemId? getters.edmSystemIdDict[systemId].email_template_content : content
        },
        recipients: recipients
      })
      if (res.data.success.length) {
        const historyData = {
          email_history_id: res.data.success[0].id,
          email_history_carrier: 'EMAIL',
          user_id,
          email_template_system_id: systemId,
          email_history_info: email_info ? JSON.stringify(email_info) : undefined,
          email_history_recipients: JSON.stringify(recipients),
          email_history_create_at: database.FieldValue.serverTimestamp()
        }
        const response = await database.table('email_history').set({
          ...historyData
        })
        commit('addHistory', { ...historyData, email_history_create_at: response.data.email_history_create_at })
      } 
      console.log(res);
      return res
    },
    async sendSMS({ commit, getters }, payload) {
      const  { systemId, content, recipients, user_id, email_info } = payload
      const res = await fetchApi('/api/surenotify/send_sms/', {
        data: { content: systemId? getters.edmSystemIdDict[systemId].email_template_content : content },
        recipients: recipients.map(e => ({ ...e, country_code: '886' }))
      })
      if (res.data.success.length) {
        const historyData = {
          email_history_id: res.data.success[0].id,
          email_template_system_id: systemId,
          email_history_carrier: 'SMS',
          user_id,
          email_history_info: email_info ? JSON.stringify(email_info) : undefined,
          email_history_recipients: JSON.stringify(recipients),
          email_history_create_at: database.FieldValue.serverTimestamp()
        }
        const response = await database.table('email_history').set({
          ...historyData
        })
        commit('addHistory', { ...historyData, email_history_create_at: response.data.email_history_create_at })
      } 
      console.log(res);
      return res
    },
    async queryEmailHistory({ commit }) {
      const { data } = await database.table('email_history').orderBy('email_history_create_at', 'desc').get()
      commit('save', {
        name: 'email_historyList', 
        data: data.map(item => ({
          ...item,
          email_history_info: JSON.parse(item.email_history_info),
          email_history_recipients: item.email_history_recipients ? JSON.parse(item.email_history_recipients)[0] : {}
        }))
      })
    }
  },
  getters: {
    edmSystemIdDict: state => {
      if (!state.email_templateList.length) return {}
      return state.email_templateList.reduce((acc, edm) => {
        acc[edm.email_template_system_id] = edm
        return acc
      }, {})
    },
    emailHistoryTemplateDict: state => {
      if (!state.email_historyList.length || !state.email_templateList.length) return {}
      return state.email_templateList.reduce((acc, edm) => {
        acc[edm.email_template_system_id] = [
          ...state.email_historyList.filter(history => history.email_template_system_id === edm.email_template_system_id)
        ]
        return acc
      }, {})
    }
  }
}
