<template>
  <header class="k-header">
    <nav>
      <ul class="k-header-links">
        <router-link to="/">
          <li>Dashboard</li>
        </router-link>
        <router-link to="/admin">
          <li>Admin</li>
        </router-link>
        <!-- <router-link to="/CRM">CRM System</router-link> -->
      </ul>
      <ul class="k-header-notice">
        <li class="k-header-notice-item">
          <k-dropdown>
            <k-icon icon="bell"></k-icon>
          </k-dropdown>
        </li>

        <li class="k-header-notice-item">
          <k-dropdown header="郵件系統">
            <k-icon icon="envelope"></k-icon>
            <k-menu
              slot="overlay"
              v-if="emailUser"
            >
              <k-menu-item to="/email/inbox">
                <k-icon
                  icon="inbox"
                  theme="success"
                />
                收件匣
              </k-menu-item>
              <k-menu-item to="/email/sent">
                <k-icon
                  icon="forward-fill"
                  theme="info"
                />
                寄件備份
              </k-menu-item>
              <k-menu-divider>標籤分類</k-menu-divider>
              <k-menu-item
                v-for="tag in emailTagList"
                :to="`/email/tag/${tag.email_tag_id}`"
                :key="`etag_${tag.email_tag_id}`"
              >
                <k-icon
                  :icon="tag.email_tag_icon || 'tag-fill'"
                  :color="tag.email_tag_color || '#6c6e7e'"
                />
                {{ tag.email_tag_name }}
              </k-menu-item>
              <k-menu-divider></k-menu-divider>
              <k-menu-item to="/email/compose">
                <k-icon
                  icon="pencil-square"
                  theme="warning"
                />
                撰寫郵件
              </k-menu-item>
              <k-menu-divider></k-menu-divider>
              <k-menu-item @click="mailLogout">
                <k-icon
                  icon="box-arrow-right"
                  theme="danger"
                />
                登出信箱
              </k-menu-item>
            </k-menu>

            <k-menu
              slot="overlay"
              v-else
            >
              <k-menu-item to="/email/login">
                <k-icon
                  icon="box-arrow-right"
                  theme="danger"
                />
                登入信箱
              </k-menu-item>
            </k-menu>
          </k-dropdown>
        </li>

        <li
          class="k-header-notice-item"
          v-if="currentUser"
        >
          <k-dropdown :header="`${currentUser.admin_name}  ${currentUser.auth_name}`">
            <k-avatar :src="`./img/glovecoding.png`"></k-avatar>
            <k-menu slot="overlay">
              <k-menu-item to="/account">
                <k-icon
                  icon="person"
                  theme="success"
                />
                Account
              </k-menu-item>
              <k-menu-item>
                <k-icon
                  icon="pie-chart"
                  theme="danger"
                />
                Sales Report
              </k-menu-item>
              <k-menu-item>
                <k-icon
                  icon="cart"
                  theme="warning"
                />
                Shopping Cart
              </k-menu-item>

              <k-menu-divider></k-menu-divider>

              <k-menu-item>
                <k-icon
                  icon="gear"
                  theme="light"
                />
                Setting
              </k-menu-item>
              <k-menu-item @click="handleLogout">
                <k-icon
                  icon="door-open"
                  theme="light"
                />
                Logout
              </k-menu-item>
            </k-menu>
          </k-dropdown>
        </li>
      </ul>
    </nav>

    <BreadCrumbs />
  </header>
</template>

<script>
import { KMenu, KMenuItem, KMenuTitle, KSubMenu, KMenuDivider } from '../../components/Menu';
import KDropdown from '../../components/Dropdown';
import KAvatar from '../../components/Avatar';
import BreadCrumbs from './BreadCrumbs'
import { mapState, mapActions } from 'vuex'

export default {
  mounted() {
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      breadCrumbLink: []
    }
  },
  methods: {
    ...mapActions('login', ['logout']),
    ...mapActions('email', ['webmailLogout']),

    async handleLogout() {
      console.log('logout');
      const hasLogout = await this.logout()
      if (hasLogout) this.$router.push('/login')
    },
    async mailLogout() {
      this.webmailLogout()
      this.$router.push('/email/login')
    }
  },
  computed: {
    ...mapState('login', ['currentUser']),
    ...mapState('email', ['emailUser', 'emailTagList'])
  },
  components: {
    KMenu, KMenuItem, KMenuTitle, KSubMenu, KMenuDivider, KDropdown, KAvatar, BreadCrumbs
  }
}
</script>
