import XHR from './xhr'

class Storage {
  static init() {
    return new Storage();
  }

  path(pathStr, onProgress=void 8) {
    return new DirRef(pathStr, onProgress)
  }
}

class DirRef extends XHR {
  constructor(path, onProgress=void 8) {
    super()
    this.path = path
    this.formData = new FormData()
    // this.uploadXHR().addEvent('progress', evt => {
    //   console.log(evt.lengthComputable);
    //   console.log(evt.loaded);
    //   console.log(evt.total);
    //   onProgress(evt)
    // });
  }

  append(key, value) {
    this.formData.append(key, value)
  }

  remove(key) {
    this.formData.delete(key)
  }

  dir() {

  }

  put(file, { onProgress, onLoad }) {
    return new Promise((resolve , reject) => {
      const API = '/api/upload/'
      this.append('path', this.path)
      this.append('file', file)
      if (onProgress) this.addEvent('progress', evt => onProgress(evt))
      this.addEvent('load', () => {
        if (this.xhr.status >= 200 && this.xhr.status < 400) {
          onLoad && onLoad(JSON.parse(this.xhr.response))
          resolve(JSON.parse(this.xhr.response))
        }
        else reject(new Error(`連結失敗${this.xhr.status}`))
      }).open('POST', API).send(this.formData)
    })
  }

  delete() {
    return new Promise((resolve, reject) => {
      const API = '/api/remove/'
      this.append('path', this.path)
      this.addEvent('load', () => {
        if (this.xhr.status >= 200 && this.xhr.status < 400) resolve(JSON.parse(this.xhr.response))
        else reject(new Error(`連結失敗${this.xhr.status}`))
      }).open('POST', API).send(this.formData)
    })
  }
}

const storage = Storage.init()

export default storage
