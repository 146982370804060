<template>
  <k-container class="view-product-container" title="所有產品">
    <!-- <k-card class="product-progress">
      <k-progress-circle :value="80" r="70" theme="warning" title="Order" />
      <k-progress-circle :value="72" r="70" theme="info" title="Success" />
      <k-progress-circle :value="9" r="70" theme="error" title="Cancel" />
    </k-card> -->

    <k-card class="product-list">
      <k-table :items="productList" :fields="fields" hover>
        <template #cell(file_path)="{item}" v-if="layoutDict['PRODUCT_AVATAR_NAME']">
          <img
            v-if="item.product_type === 'PRODUCT'"
            @error="e => ImgError(e)"
            :src="
              `https://chris-farrell.com.tw/img/product/${item.product_uid}/${layoutDict['PRODUCT_AVATAR_NAME'].layout_content || 'avatar'}.webp`
            "
          >
          <div class="img-wrapper" v-else>
            <img :src="`https://chris-farrell.com.tw/img/product/${item.product_images[0]}`" alt="">
          </div>
        </template>

        <template #cell(product_name_zh)="{value, item}">
          <h3 class="name_en">{{ item.product_name_en }}</h3>
          <h2 class="name_zh">{{ value }}</h2>
        </template>

        <template #cell(product_price)="{value, item}">
          <ul class="price-classes">
            <li v-for="price_class in Object.keys(value)" :key="`price_${item.product_uid}_${price_class}`">
              {{ priceClassDict[price_class] }}: <span>${{ value[price_class] }}</span>
            </li>
          </ul>
        </template>

        <template #cell(product_status)="item">
          <k-tag :theme="item.item.product_quantity===0 ? 'error' : 'success'">
            {{ item.item.product_quantity===0 ? '補貨中' : '尚有庫存' }}
          </k-tag>
        </template>

        <template #cell(product_is_additional)="{value, item}">
          <k-switch :value="value === '1'" @input="bool => handleAdditionalStateChange(item.product_id, bool)" theme="info"></k-switch>
        </template>

        <template #cell(product_is_launched)="{value}">
          <k-switch :value="value === '1'" theme="success"></k-switch>
        </template>

        <template #cell(product_states)="item">
          <k-progress-star :value="item.value" showValue></k-progress-star>
        </template>

        <template #cell(action)="{item}">
          <k-button varient="ghost" @click="changeFocusProduct(item.product_id)" theme="info">詳細資訊</k-button>
        </template>
      </k-table>
    </k-card>

    <k-modal
      class="product-detail-modal"
      :show.sync="showProduct"
      :hideFooter="true"
      title="訂單操作"
    >
      <template v-if="focusProductInfo">
        <header>
          <img style="width: 200px; border-radius: 8px;"
            @error="e => ImgError(e)"
            :src="
              `https://chris-farrell.com.tw/img/product/${focusProductInfo.product_uid}/${layoutDict['PRODUCT_AVATAR_NAME'].layout_content || 'avatar'}.webp`
          ">
          <div class="content">
            <h2>{{ focusProductInfo.product_name_en }}</h2>
            <h1>{{ focusProductInfo.product_name_zh }}</h1>
            <h4>貨號: {{ focusProductInfo.product_uid }}</h4>
          </div>
        </header>
        <k-descriptions layout="vertical" bordered>
          <k-descriptions-item label="貨號">{{ focusProductInfo.product_uid }}</k-descriptions-item>
          <k-descriptions-item label="中文名稱">{{ focusProductInfo.product_name_zh || '-' }}</k-descriptions-item>
          <k-descriptions-item label="英文名稱">{{ focusProductInfo.product_name_en || '-' }}</k-descriptions-item>
          <k-descriptions-item label="產品價格" span="3">{{ focusProductInfo.product_price }}</k-descriptions-item>
          <k-descriptions-item label="產品說明" span="3">{{ focusProductInfo.product_summary }}</k-descriptions-item>
          <k-descriptions-item label="產品重點" span="3">
            <k-input v-model="focusProductInfo.product_characters[0]" placeholder="單筆滿1000元免運費" type="text"></k-input>
            <k-input v-model="focusProductInfo.product_characters[1]" placeholder="單附贈市價250元試用品" type="text"></k-input>
            <k-input v-model="focusProductInfo.product_characters[2]" placeholder="單筆消費滿1元就送德國美容院旅行組" type="text"></k-input>
            <k-input v-model="focusProductInfo.product_characters[3]" placeholder="單筆消費滿3000送植萃多酚柔膚水200ml(市價$1550)" type="text"></k-input>
          </k-descriptions-item>
        </k-descriptions>
      </template>
    </k-modal>
  </k-container>
</template>

<script>
import { KProgress, KProgressCircle, KProgressStar } from '@/components/Progress'
import KTag from '@/components/Tag'
import KCard from '@/components/Card'
import KTable from '@/components/Table'
import KModal from '@/components/Modal'
import { KSwitch, KInput } from '@/components/Form'
import { KDescriptions, KDescriptionsItem } from '@/components/Descriptions'
import { KTabs, KTabPane } from '@/components/Tabs'
import KButton from '@/components/Button'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Product',
  mounted() {
    this.$store.dispatch('product/query')
  },
  data() {
    return {
      fields,
      showProduct: false,
      focusProductInfo: null,
      priceClassDict: { ORIGIN: '原價', ADDITIONAL: '加購價', MEMBER: '會員價' }
    }
  },
  computed: {
    ...mapState('product', ['productList']),
    ...mapGetters('layout', ['layoutDict'])
  },
  methods: {
    ...mapActions('product', ['update']),
    ImgError(event) {
      event.target.src = "https://admin.chris-farrell.com.tw/static/product_default_img.png";
      return true;
    },
    handleAdditionalStateChange(product_id, bool) {
      this.update({ id: product_id, product_is_additional: bool ? '1' : '0' })
      
    },
    changeFocusProduct(product_id) {
      const product = this.productList.filter(item => item.product_id === product_id)[0]
      this.focusProductInfo = JSON.parse(JSON.stringify(product))
      this.showProduct = true
    }
  },
  components: {
    KProgressCircle, KCard, KTable, KTag, KProgress, KProgressStar, KSwitch, KModal, KDescriptions, KDescriptionsItem, KTabs, KTabPane, KButton, KInput
  }
}

const fields = [
  { key: 'product_uid', fieldIndex: 'product_uid', label: '貨號' },
  { key: 'file_path', fieldIndex: 'file_path', label: '產品首圖' },
  { key: 'product_name_zh', fieldIndex: 'product_name_zh', label: '產品名稱', sorter: (a, b) => ('' + a).localeCompare(b), searchable: true },
  { key: 'product_price', fieldIndex: 'product_price', label: '價格', sorter: (a, b) => b - a },
  { key: 'product_type', fieldIndex: 'product_type', label: '產品類型', filters: [
    { value: 'PRODUCT', text: '產品' },
    { value: 'SET', text: '組合' },
  ] },
  // { key: 'product_states', fieldIndex: 'product_states', label: '評分', sorter: (a, b) => b - a },
  { key: 'product_is_additional', fieldIndex: 'product_is_additional', label: '開放加價購', filters: [
    { value: '1', text: '已開啟' },
    { value: '0', text: '未開放' },
  ] },
  { key: 'product_is_launched', fieldIndex: 'product_is_launched', label: '上架', filters: [
    { value: '1', text: '已上架' },
    { value: '0', text: '未上架' },
  ] },
  { key: 'action', fieldIndex: 'action', label: '' }
]
</script>
