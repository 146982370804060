import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './config';
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const { loginState } = store.state.login
  if (to.name !== 'Login' && loginState===false) next({ name: 'Login' })
  next()
})

export default router
