import { timestampParse, fetchApi } from '@/utils'
import { genBasicModel } from './utils/basicAction'
import database from '@/utils/database'
import storage from '@/utils/storage'

const MODEL_NAME = 'media'
const { basicMutation, basicAction } = genBasicModel(MODEL_NAME, query)
async function query() {
  const response = await database.table('file')
    .join('file_use', 'file_id')
    .join('post', 'post_id', 'file_use')
    .join('category', 'category_id', 'file_use')
    .join('product', 'product_id', 'file_use')
    .orderBy('file.file_uploadAt', 'desc')
    .get()
  response.data = response.data.map(d => ({
    ...d,
    file_uploadAt: timestampParse(d.file_uploadAt),
    file_type: d.file_type.split('/')[0],
    useIn: imageUseIn(d),
    file_size: fileSizeFormat(d.file_size, 'Kb'),
    product_id: ['0', null].includes(d.product_id) ? null : d.product_id
  }))
  return response
}

function imageUseIn(info) {
  if (parseInt(info.post_id) > 0) return 'Post'
  if (parseInt(info.category_id) > 0) return 'Category'
  if (parseInt(info.product_id) > 0) return 'Product'
  return ''
}

function fileSizeFormat(size, type) {
  switch (type) {
    case 'Kb':
      return Math.round(size/1024, 2)
    case 'Mb':
      return Math.round(size/1024/1024, 2)
    case 'Gb':
      return Math.round(size/1024/1024/1024, 2)
    default:
      return Math.round(size/1024, 2)
  }
}

export default {
  namespaced: true,
  state: {
    mediaTree: null,
    mediaLayers: null,
    mediaList: [],
    sortType: null,
  },
  mutations: {
    ...basicMutation,
    saveMediaTree(state, payload) {
      state.mediaTree = payload
    },
    saveMediaLayers(state, payload) {
      state.mediaLayers = payload
    },
    saveFile(state, payload) {
      state.mediaList = [{
        ...payload,
        file_uploadAt: timestampParse(payload.file_uploadAt),
        file_size: fileSizeFormat(payload.file_size, 'Kb')
      }, ...state.mediaList]
    },
    insertFile(state, payload) {
      const { file, parrent_path } = payload
      const fileSplit = file.name.split('.')
      const extension = fileSplit[fileSplit.length - 1]
      const name = fileSplit.slice(0, fileSplit.length - 1).join('.')
      console.log(parrent_path);
      console.log(state.mediaLayers[parrent_path]);
      state.mediaLayers[parrent_path].file.splice(0,0, {
        name, extension, file: file.name, fileType: 'IMAGE', parentRout: parrent_path
      })
    },
    insertDir(state, payload) {
      const { name, path } = payload
      const dirInfo =  { path: `${path}/${name}`, file: [], dir: [] }
      state.mediaLayers[`${path}/${name}`] = dirInfo
      state.mediaLayers[path].dir.push(dirInfo)
    },
    deleteFile(state, payload) {
      state.mediaList = state.mediaList.filter(f => f.key_id !== payload)
    },
    changeSortType(state, payload) {
      state.sortType = payload
    }
  },
  actions: {
    ...basicAction,
    async fetchMediaTree({ commit }, payload) {
      const { mediaTree } = await fetchApi('/api/getFile/', payload)
      const mediaLayers = parseLayer(mediaTree)
      commit('saveMediaTree', mediaTree)
      commit('saveMediaLayers', mediaLayers)
    },
    async insertFileInfo({ commit }, payload) {
      const { file, parrent_path } = payload
      //database function
      commit('insertFile', { file, parrent_path })
    },
    async insertDir({ commit }, payload) {
      const { name, path } = payload
      commit('insertDir', { name, path })
    },
    async uploadFile({ commit }, payload) {
      const { file, path } = payload
      const API = '/api/file/'
      const { state, locations, files, message } = await fetchApi(API, { file, path })
      console.log({ state, locations, files, message });
      if (state) {
        commit('saveFile', { file: files[file.name] })
        return true
      }
      return false
    },
    // async insertFileInfo({ commit }, payload) {
    //   const { file, file_path, upload_at } = payload
    //   const response = await database.table('file').set({
    //     file_name: file.name, file_type: file.type, file_size: file.size, file_path, file_uploadAt: upload_at
    //   })
    //   console.log(response);
    //   if (response.status === 200) {
    //     commit('saveFile', { key_id: response.id, ...response.data })
    //     return response.id
    //   }
    // },
    async registerFileUse(_, payload) {
      const { file_id, category_id, post_id, product_id } = payload
      const response = await database.table('file_use').set({ file_id, category_id, post_id, product_id })
      return response
    },
    async unlinkFileUse(_, payload) {
      const { useType, useTypeId, fileIds, keepFile=true } = payload
      const fileIdArr = Array.isArray(fileIds) ? fileIds : [fileIds]
      const response = await database.table('file_use')
        .where(`${useType}_id`, '=', useTypeId).where('file_id', 'in', fileIdArr).delete()
      if (!keepFile) {
        const deleteFileIds = response.data.map(d => d.file_id)
        const deleteFiles = await database.table('file').where(`file_id`, 'in', deleteFileIds).delete()
        deleteFiles.data.forEach(info => storage.path(info.file_path).delete());
      }
      return response
    },
    async deleteFile({ commit }, payload) {
      const { key_id, file_path } = payload
      const storeageRes = await storage.path(file_path).delete();
      const databaseRes = await database.table('file').where('file_id', '=', key_id).delete()
      if (storeageRes.status===200 && databaseRes.status===200) {
        commit('deleteFile', key_id)
        return databaseRes
      }
    },
    changeSortType({ commit }, payload) {
      commit('changeSortType', payload)
    },
    async uploadFileList( _, { fileList, path }) {
      const API = '/api/file/'
      const formData = Object.entries(fileList).reduce((acc, [column, files]) => {
        if (Array.isArray(files)) {
          return files.reduce((accumulator, f) => {
            accumulator.append(`${column}[]`, f)
            return accumulator
          },acc)
        }
        else acc.append(column, files)
        return acc
      }, new FormData())
      if (path) formData.append('path', path)

      const fileResponse = await fetch(API, { body: formData, method: 'POST' })
      const json = await fileResponse.json()
      return json
    },
  },
  getters: {
    // mediaLayers: ({ mediaTree }) => {
    //   if (mediaTree) {
    //     return parseLayer(mediaTree)
    //   }
    //   return {}
    // },
    recentFile: ({ mediaList }) => {
      console.log(mediaList);
      return mediaList.slice(0, mediaList.length < 5 ? length : 7)
    },
    filterByMediaType: ({ mediaList, sortType }) => {
      const newMediaList = [...mediaList]
      return newMediaList
      .sort((a,b) => {
        if (sortType === 'file_name') return ('' + a[sortType]).localeCompare(b[sortType])
        if (sortType === 'file_uploadAt') return b[sortType] - a[sortType]
      })
      .reduce((acc, f) => {
        if (!f.post_id && !f.category_id && !f.product_id) acc.unused = [...acc.unused, { ...f }]
        else {
          const index = acc[f.file_type].map(e => e.key_id).indexOf(f.key_id)
          if (index === -1) {
            acc[f.file_type] = [...acc[f.file_type], { ...f, post_id: [f.post_id] }]
          } else {
            acc[f.file_type][index].post_id.push(f.post_id)
          }
        }
        return acc
      } , { image: [], video: [], unused: [] })
    }
  }
}

function parseLayer(layer) {
  console.log(layer.path);
  return {
    [layer.path]: layer,
    ...layer.dir.reduce((acc, l) => ({ ...acc, ...parseLayer(l) }), {})
  }
}
