<template>
  <span class="k-tooltip" @mouseenter="toggleTip(true)" @mouseleave="toggleTip(false)">
    <slot></slot>
    <div :class="classes" ref="content" :style="placeStyle">    
      <p>{{ title }}</p>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    title: String,
    placement: {
      type: [String, Array],
      default: 'right'
    }
  },
  mounted() {
    this.offsetHeight = this.$refs.content.offsetHeight
    this.offsetWidth = this.$refs.content.offsetWidth
  },
  data() {
    return {
      show: false,
      offsetHeight: null,
      offsetWidth: null
    }
  },
  computed: {
    classes() {
      return {
        'tooltip-content': true,
        [this.placement]: true,
        show: this.show
      }
    },
    placeStyle() {
      const placeDict = {
        right: { right: `${this.offsetWidth * -1 -4}px` },
        bottom: { bottom: `${this.offsetHeight * -1 -10}px` },
        left: { left: `${this.offsetWidth * -1 -4}px` },
        top: { top: `${this.offsetHeight * -1 -10}px` }
      }
      const placementArr = Array.isArray(this.placement) ? this.placement : [this.placement]
      return placementArr.reduce((acc, cur) => ({ ...acc, ...placeDict[cur] }), {})
    }
  },
  methods: {
    toggleTip(nextState) {
      this.show = nextState
    }
  },
}
</script>
