import { genBasicModel } from './utils/basicAction'
import { create, update } from './utils/basicService'
import database from '@/utils/database'

const MODEL_NAME = 'product'
const { basicAction, basicMutation } = genBasicModel(
  MODEL_NAME,
  query,
  create(MODEL_NAME, true),
  update(MODEL_NAME))

const productOrder = ['4001', '4004', '2012', '2716', '2709', '2001', '2004', '2080', '2501']
//把陣列中依照 productOrder 用屬性 product_uid 排序，如果 product_uid 沒有在 productOrder 中就放在最後面
function sortProductList(productList) {
  return productList.sort((a, b) => {
    if (productOrder.indexOf(a.product_uid) === -1) return 1
    return productOrder.indexOf(a.product_uid) - productOrder.indexOf(b.product_uid)
  })
}

async function query() {
  const res = await database.table(MODEL_NAME).get()
  res.data = sortProductList(res.data)
  res.data = res.data.map(item => ({
    ...item,
    product_images: item.product_images ? item.product_images.split('|') : [],
    product_price: JSON.parse(item.product_price),
    product_characters: item.product_characters ? item.product_characters.split('|') : Array(4).fill(''),
  }))
  console.log(res.data);
  return res
}

export default {
  namespaced: true,
  state: {
    productList: []
  },
  mutations: {
    ...basicMutation,
  },
  actions: {
    ...basicAction,
    async createStructureData({ commit }, payload) {
      const { id, ...formData } = payload
      const response = await database.table('product').where('product_id', '=', id).update(formData)
      if (response.status === 200) commit('updateProduct', { id, ...formData })
      return response
    }
  },
  getters: {
    productDict({ productList }) {
      if (productList.length) {
        return productList.reduce((acc, p) => {
          return { ...acc, [p.product_id]: { ...p } }
        }, {})
      }
      return {}
    },
    productUidDict({ productList }) {
      if (productList.length) {
        return productList.reduce((acc, p) => {
          return { ...acc, [p.product_uid]: { ...p } }
        }, {})
      }
      return {}
    }
  }
}
