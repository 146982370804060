import { genBasicModel } from './utils/basicAction'
import database from '@/utils/database'
import { timestampParse } from '@/utils'
import moment from 'moment'

const MODEL_NAME = 'user'
const { basicAction, basicMutation } = genBasicModel(MODEL_NAME, query)

async function query() {
  const res = await database.table(MODEL_NAME)
  .where('user_uid', 'NOT LIKE', '%mock%')
  .where('user_uid', 'isNull', '', 'OR')
  .get()
  return res
}

export default {
  namespaced: true,
  state: {
    userList: []
  },
  mutations: {
    ...basicMutation,
    levelUpMember(state, payload) {
      const userIndex = state.userList.map(u => u.user_id).indexOf(payload)
      state.userList.splice(userIndex, 1, { ...state.userList[userIndex], user_group: 'ORIGINAL' })
    }
  },
  actions: {
    ...basicAction,
    async levelUpMember({ commit, state }, payload) {
      const userIndex = state.userList.map(u => u.user_id).indexOf(payload)
      const userOldCoupon = JSON.parse(state.userList[userIndex].user_own_coupons) || []
      const originalCouponRes = await database.table('coupon').where('coupon_super', '=', 'ORIGINAL_LEVEL_UP_200').get()
      const res = await database.table('user').where('user_id', '=', payload).update({
        user_group: 'ORIGINAL',
        user_own_coupons: JSON.stringify([...userOldCoupon, { coupon_id: originalCouponRes.data[0].coupon_id, coupon_get_at: moment().unix() }])
      })
      commit('levelUpMember', payload)
      return res
    },
    async issueVoucher(_, payload) {
      const { user_id, voucher_value } = payload
      await database.table('voucher').set({
        voucher_type: 'BIRTHDAY',
        user_id,
        voucher_value,
        voucher_period: 31536000,
        voucher_create_at: database.FieldValue.serverTimestamp()
      })
      return { status: 200 }
    }
  },
  getters: {
    userDict: ({ userList }) => {
      if (userList.length) {
        return userList.reduce((acc, user) => ({ ...acc, [user.user_id]: { ...user } }), {})
      }
      return null
    },
    monthlyNewUser: ({ userList }) => {
      if (userList.length) {
        const thisYear = moment([2023, 0, 1]).unix()
        return userList.filter(u => u.user_create_at && parseInt(u.user_create_at) > thisYear).reduce((acc, u) => {
          const month = parseInt(timestampParse(u.user_create_at).format('M')) - 1
          acc[month] += 1
          return acc
        }, Array(12).fill(0))
      }
      return Array(12).fill(0)
    }
  }
}
