<script>
import KButton from '@/components/Button'
import KModal from '@/components/Modal'
import { KInput } from '@/components/Form'

export default {
  name: 'K-Prompt',
  data() {
    return {
      show: false,
      title: '',
      type: null,
      callback: void 0,
      value: '',
      icon: '',
      text: '',
      typeIcon: {
        success: 'bi-check-circle-fill',
        warning: 'bi-exclamation-octagon-fill',
        error: 'bi-x-circle-fill',
        info: 'bi-info-circle-fill',
      }
    }
  },
  methods: {
    showPrompt(title, callback, type, text='', icon='') {
      this.show = true
      this.title = title
      this.callback = callback
      this.type = type
      this.text = text
      this.icon = icon
    },
    handleClick(bool) {
      if (bool) this.callback(this.value)
      this.show = false
    }
  },
  computed: {
    classes() {
      return {
        'k-confirm': true,
        'show': this.show,
      }
    },
    spanClasses() {
      return {
        [`confirm--${this.type}`]: this.type
      }
    }
  },
  render(h) {
    return (
      <KModal class="k-prompt" show={this.show} closable={false} hideHeader hideFooter>
        <span class={this.spanClasses}>
          <i class={`bi ${this.icon ? 'bi-'+this.icon : this.typeIcon[this.type]}`} />
          <b>{this.title}</b>
          <p domPropsInnerHTML={this.text.replace(/\n/g, '<br>')}></p>
          <KInput
            ref="input"
            value={this.value}
            onChange={value => this.value = value}
            placeholder="enter..."
          />
        </span>

        <KButton label="取消" theme="light" onClick={() => this.handleClick(false)} />
        <KButton label="確認" theme="warning" onClick={() => this.handleClick(true)} />
      </KModal>
    )
  },
  component: {
    KModal, KButton, KInput
  }
}
</script>
